import React from "react";
import ProjectLogo from "../UI/ProjectLogo";
import SocialLink from "../UI/SocialLink";
import Logo from "../UI/Logo";
import DateFormat from "../UI/DateFormat";
import PriceFormat from "../UI/PriceFormat";
import "./Preview.css";
import SalesOn from "./SalesOn";

const MoonSheetPreview = ({ moonSheet }) => {
  console.log(moonSheet)
  return (
    <section className="relative">
      <div className="moonsheet-bg absolute top-0 w-full h-full bg-center bg-cover">
        <span className="w-full h-full absolute opacity-25 bg-black"></span>
      </div>
      <div className="container moonsheet relative mx-auto">
        <div className="moonsheet-header p-4">
          <Logo className="small self-start" />
          {moonSheet?.project && (
            <ProjectLogo className="big" logo={moonSheet.project} />
          )}
          <Logo className="small self-start" />
        </div>
        <h1 className="moonsheet-title mb-4">Moon Sheet</h1>
        <table>
          <thead>
            <tr>
              <th>ROI</th>
              <th>PRICE</th>
              <th>MARKET CAP</th>
              {moonSheet?.isLogoVisible !== "0" && <th>PROJECT</th>}
            </tr>
          </thead>
          <tbody>
            {moonSheet?.tableData?.length &&
              moonSheet.tableData.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {item.roi}
                      <span className="ext">X</span>
                    </td>
                    <td>
                      <PriceFormat value={+item.initial_price} />
                    </td>
                    <td>
                      <PriceFormat value={+item.initial_market_cap} />
                    </td>
                    {moonSheet?.isLogoVisible !== "0" && (
                      <td>
                        {item.project ? <ProjectLogo logo={item.project} /> : "-"}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <SalesOn project={moonSheet.project} />
        <div className="flex justify-between">
          <div className="flex items-center ml-2">
            <p className="ml-2 text-white font-semibold">Created On:</p>
            <DateFormat
              className="ml-2 text-white font-semibold"
              date={moonSheet.modifiedAt}
            />
          </div>
          <SocialLink />
        </div>
      </div>
    </section>
  );
};

export default MoonSheetPreview;
