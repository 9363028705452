import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import { toastNotification } from "common/util_function";
import "../../assets/styles/scrollbar.css";
import BasicStep from "./BasicStep";
import AdvancedStep from "./AdvancedStep";
import FundRaising from "./FundRaising";
import ProjectTeam from "./Team";
import Tab from "components/UI/Tab";
import { formattedDateTime } from "common/util_function";

const defaultTabList = [
  {
    name: "Basic Details",
    key: "basic",
    active: true,
  },
  {
    name: "Advanced Info",
    key: "advanced",
    active: false,
  },
  {
    name: "Fund Raising",
    key: "fundraising",
    active: false,
  },
  {
    name: "Teams",
    key: "projteams",
    active: false,
  },
];

const ProjectForm = ({ formSubmit, formData: fData, config }) => {
  const [file, setFile] = useState("");
  const [addfile, setaddFile] = useState("");
  const [formData, setFormData] = useState(fData);
  const [tabList, setTabList] = useState(defaultTabList);
  const [selectedTab, setSelectedTab] = useState({});
  const [isValidTGE, setIsValidTGE] = useState(true);
  useEffect(() => {
    setSelectedTab(defaultTabList[0]);
    return () => { }
  }, []);

  const selectedTabHandler = (tab) => {
    const updatedTabList = tabList.map((item) => {
      if (item.key === tab.key) {
        const newItem = { ...item, active: true };
        setSelectedTab(newItem);
        return newItem;
      }
      return { ...item, active: false };
    });
    setTabList(updatedTabList);
  };

  const onSubmit = async (fields) => {
    if (isValidTGE) {
      const { websiteURL, twitterURL, githubURL, mediumURL, telegramAnnouncementURL, telegramCommunityURL, discordURL,tokenomicslink,projectdocument,whitePaperLink, ...others } = fields;
      
      if (Array.isArray(fields.investors)) {
        const { investors, ...datai } = others;
        const investorsIds = investors.map(item => +item.id).join(",");
        fields = { ...datai, investors: investorsIds }
      }

      if (Array.isArray(fields.othinvestors)) {
        const { othinvestors, ...dataoi } = others;
        const othinvestorsIds = othinvestors.map(item => +item.id).join(",");
        fields = { ...dataoi, othinvestors: othinvestorsIds }
      }
      
      const socialLinks = [{ "web": websiteURL }, { "twitter": twitterURL }, { "github": githubURL }, { "medium": mediumURL }, { "telegram": telegramAnnouncementURL }, { "telegramCommunity": telegramCommunityURL }, { "discord": discordURL }, { "tokenomics": tokenomicslink }, { "projectdocument": projectdocument }, { "whitepaper": whitePaperLink }];
      await formSubmit({ ...fields, socialLinks}, file, addfile);
    } else {
      toastNotification("error", "Please keep sales round allocation is 100%");
    }
  }

  return (
    <Formik initialValues={formData} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, resetForm }) => (
        <Form noValidate className="min-h-screen-75">
          <Tab list={tabList} selectedTab={selectedTabHandler}>
            {
              selectedTab.key === "basic" ? <BasicStep formFields={formData} setFieldValue={setFieldValue} config={config} values={values} file={setFile} addfile={setaddFile} /> : selectedTab.key === "advanced" ? <AdvancedStep formFields={formData} setFieldValue={setFieldValue} config={config} values={values} validateTGE={(value) => {
                setIsValidTGE(value);
              }} /> : selectedTab.key === "fundraising" ? <FundRaising formFields={formData} setFieldValue={setFieldValue} config={config} values={values} /> : selectedTab.key === "projteams" ? <ProjectTeam formFields={formData} setFieldValue={setFieldValue} config={config} values={values} /> : ''
            }
          </Tab>
          <div className="px-4 py-2 border-t-2 flex items-center justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" onClick={() => setFormData(fData)}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"> Submit </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ProjectForm;