import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from 'services/user.service';
import { toastNotification } from "common/util_function";
import PForm from "components/Project/Form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const defaultData = {
  id: "",
  projectName: "",
  slug: "",
  description: "",
  status: 1,
  isLaunchpad: 0,
  logoFileName: "",
  additionalFileName: "",
  tokenPrice: "",
  initialMarketCap: "",
  tokenName: "",
  category: [],
  blockChain: [],
  accessTypes: [],
  investors: [],
  websiteURL: "",
  twitterURL: "",
  telegramCommunityURL: "",
  telegramAnnouncementURL: "",
  discordURL: "",
  mediumURL: "",
  githubURL: "",
  initialTokenSupply: "",
  totalSupply: "",
  addfileName: "",
  tokenomicslink: "",
  projectdocument: "",
  teamsvalue: [
    {
      name: "",
      role: "",
      social1: "",
      social2: ""
    }
  ],
  tableData: [
    {
      round: "",
      initialPrice: "",
      tokenTGE: "",
      allocationper: "",
      tokenFundraised: "",
      cliffCategory: "",
      cliff: "",
      vestingCategory: "",
      vesting: "",
      vestingInterval: ""
    }
  ],
  fundingData: [
    {
      fundRound: "",
      totalRaised: "",
      fundMonth: "",
      fundYear: "",
      leadInvestors: "",
      otherInvestors: "",
      announcement: ""
    }
  ],
}

const defaultConfig = {
  ecosystems: [],
  investors: [],
  accessTypes: [],
  salesRounds: [],
  fundingRounds: [],
  projectCategorites: []
}

const ProjectForm = (props) => {
  const [config, setConfig] = useState(defaultConfig);
  const editData = { ...props?.location?.data, tableData: props?.location?.data?.tableData.length ? props?.location?.data?.tableData : defaultData.tableData, fundingData: props?.location?.data?.fundingData.length ? props?.location?.data?.fundingData : defaultData.fundingData, teamsvalue: props?.location?.data?.teamsvalue.length ? props?.location?.data?.teamsvalue : defaultData.teamsvalue };
  
  const [formData, setFormData] = useState({ ...defaultData, ...editData })
  const history = useHistory();
  useEffect(() => {
    const loadData = async () => {
      const ecosystems = await getEcosystem();
      const investors = await getInvestors();
      const projectCategorites = await getCategoryList(8);
      const accessTypes = await getCategoryList(6);
      const salesRounds = await getCategoryList(7);
      const fundingRounds = await getCategoryList(7);
      setConfig({ ...config, ecosystems, investors, accessTypes, salesRounds, fundingRounds, projectCategorites });
    }
    loadData();
    return () => {
      setConfig(defaultConfig)
    }
  }, []);

  const getEcosystem = async () => {
    const url = URLs.ecosystem.all;
    return await axios.get(url).then((response) => response.data.data);
  }

  const checkValidations = (fields) => {
    if (!fields.projectName) {
      toastNotification("error", "Project name should not be empty");
      return false;
    }
    if (!fields.slug) {
      toastNotification("error", "Project slug name should not be empty");
      return false;
    }
    if (!fields.file && !fields.logoFileName) {
      toastNotification("error", "Project image should not be empty");
      return false;
    }
    return true;
  }

  const getCategoryList = async (typeId) => {
    const url = `${URLs.category.list}?typeId=${typeId}`;
    return await axios.get(url).then((response) => response.data.data);
  };

  const getInvestors = async () => {
    const url = URLs.investors.all;
    return await axios.get(url).then((response) => response.data.data);
  };

  const onSubmit = async (fields, file, addfile) => {
    if (checkValidations({ ...fields, file })) {
      const { socialLinks, tableData, fundingData, teamsvalue, accessTypes, blockChain, investors, projectCategorites, ...otherData } = fields;
      const userId = userService.userId();
      const formSubmitData = new FormData();
      for (const key in otherData) {
        formSubmitData.append(key, fields[key]);
      }
    
      formSubmitData.append("socialLinks", JSON.stringify(socialLinks));
      formSubmitData.append("tableData", JSON.stringify(tableData));
      formSubmitData.append("fundingData", JSON.stringify(fundingData));
      formSubmitData.append("accessTypes", JSON.stringify(accessTypes));
      formSubmitData.append("blockChain", JSON.stringify(blockChain));
      formSubmitData.append("investors", JSON.stringify(investors));
      formSubmitData.append("teamsValue", JSON.stringify(teamsvalue));
      formSubmitData.append("othinvestors", JSON.stringify(investors));
      formSubmitData.append("category", JSON.stringify(projectCategorites));
      formSubmitData.append("userId", userId);

      if (file) {
        formSubmitData.append("file", file, file.name)
      }
      if (addfile.length>0) {
        for (var x = 0; x < addfile.length; x++) {
          formSubmitData.append("addfile[]", addfile[x]);  
       }
      }
      
      const config = {
        method: "post",
        url: URLs.project.form,
        data: formSubmitData,
      };
      try {
        const response = await axios(config);
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          setFormData(defaultData);
          history.push("/admin/projects");
        } else {
          toastNotification("error", response.data.message);
        }
      } catch (error) {
        console.log(error);
        toastNotification("error", error.message);
      }
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Project Form</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/projects"
              >
                Back
              </Link>
            </div>
          </div>
          <div className="w-full">
            <PForm formSubmit={onSubmit} formData={formData} config={config} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectForm;
