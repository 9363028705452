import React from "react";

const Status = (props) => {
  return (
    <div
      className={`mt-1 w-5 h-5 rounded-full ${
        +props.value ? "bg-green-400" : "bg-red-400"
      }`}
    ></div>
  );
};

export default Status;
