import React, { useState } from "react";
import { Formik, Field, Form } from 'formik';
import LogoDropdown from "components/UI/LogoDropdown";
import { Multiselect } from "multiselect-react-dropdown";

const ProjectFilter = ({ logoList, onFilter, ecosystem }) => {
  const [titleLogo, setTitleLogo] = useState({});
  const [blockChains, setBlockChains] = useState([]);
  const defaultData = {
    project: '',
    blockChain: '',
    tokenName: '',
    isLaunchpad: false
  }

  const multiStyle = {
    chips: { background: "gray-300" },
    searchBox: {
      border: "none",
      borderRadius: "0px",
      padding: "0.5rem 1rem"
    },
  };

  // handle title logo change
  const onTitleLogoHandler = (item) => {
    setTitleLogo(item);
  };

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    const { project, blockChain, tokenName, isLaunchpad } = fields;
    setSubmitting(true);
    setStatus(true);
    await onFilter({ project, blockChain, tokenName, isLaunchpad })
  }

  return (
    <div className="p-4">
      <div className="my-4 p-4 border-b-2 bg-white rounded">
        <Formik initialValues={defaultData} onSubmit={onSubmit}>
          {({ values, setFieldValue, isSubmitting, handleReset }) => (
            <Form noValidate className="flex flex-col">
              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold">BlockChain</label>
                <div className="w-full border-1 rounded cursor-pointer outline-none focus:bg-gray-300">
                  <Multiselect
                    options={ecosystem}
                    displayValue="name"
                    showCheckbox={true}
                    placeholder="Select Option"
                    name="blockChain"
                    closeOnSelect={false}
                    style={multiStyle}
                    selectedValues={blockChains}
                    hidePlaceholder={true}
                    onSelect={(selectedList) => {
                      setBlockChains(selectedList);
                      const ids = selectedList.map(item => +item.id).join(",");
                      setFieldValue("blockChain", ids);
                    }}
                    onRemove={(selectedList) => {
                      setBlockChains(selectedList);
                      const ids = selectedList.map(item => item.id).join(",");
                      setFieldValue("blockChain", ids);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap items-end justify-between">
                <div className="mr-2 mb-2">
                  <label className="block text-xs mb-2 uppercase font-bold">Project</label>
                  <LogoDropdown
                    className="w-full"
                    list={logoList}
                    selectedProjectItem={titleLogo}
                    onLogo={(item) => {
                      onTitleLogoHandler(item);
                      setFieldValue('project', item.id)
                    }}
                    name="project"
                    value={values.project}
                    onChange={e => setFieldValue('project', e)}
                  />
                </div>

                <div className="mr-2 mb-2">
                  <label className="block text-xs mb-2 uppercase font-bold">Token Name</label>
                  <Field type="text" name="tokenName" className="w-full p-2 border-1 rounded cursor-pointer outline-none focus:bg-gray-300" placeholder="Token Name" autoComplete="off" />
                </div>
                {/* <Field as="select" name="blockChain" className="w-full p-2 border-1 rounded cursor-pointer outline-none focus:bg-gray-300">
                <option value="">--Select Option--</option>
                {ecosystem?.length && ecosystem.map(item => {
                  return <option key={item.id} value={item.id}>{item.name}</option>
                })}
              </Field> */}

                <div className="mr-4 mb-2">
                  <label className="block text-xs mb-2 uppercase font-bold text-left flex align-items">
                    <Field type="checkbox" className="mr-2 form-checkbox h-5 w-5 text-purple-600" name="isLaunchpad" />
                    Launhpad
                  </label>
                </div>

                <div className="flex flex-row mb-2">
                  <button type="reset" className="w-full bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-2 rounded mr-2" onClick={() => {
                    handleReset();
                    setBlockChains([]);
                    setTitleLogo({});
                  }}>Clear</button>
                  <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 text-white focus:outline-none font-bold py-2 px-2 rounded">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>}
                    Search
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProjectFilter;