import { imageURL } from "constants/urls";
import React from "react";
import "./AdditionalInfo.css";

const AdditionalInfo = ({ className, addlogo, path = "projects/additional-info", isHideLabel = false, width = 24, height = 24 }) => {
  return (
    <div className={`additional-logo ${className ? className : ""}`}>
      {addlogo?.imageName[0] !== undefined && (
        <React.Fragment>
        <>
            {JSON.parse(addlogo?.imageName).map(p => {
              return <img
              className="img"
              src={`${imageURL}/${path}/${p}`}
              width={width}
              height={height}
            />;
            })}
            
          
          {isHideLabel !== "true" && <span>{addlogo.addname}</span>}
          </>
        </React.Fragment>
      )}
    </div>
  );
};

export default AdditionalInfo;
