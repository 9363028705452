import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "./Sidebar";
import EcoSystem from "views/admin/EcoSystem";
import EventType from "views/admin/EventType";
import Posts from "views/admin/Posts";
import MoonSheet from "views/admin/MoonSheet";
import Airdrops from "views/admin/Airdrops";
import AirdropsForm from "views/admin/Airdrops/Form";
import Projects from "views/admin/Projects";
import ProjectForm from "views/admin/Projects/Form";
import Campaigns from "views/admin/Campaigns";
import FeaturedProjects from "views/admin/FeaturedProjects";
import TopLaunchpads from "views/admin/TopLaunchpads";
import PostForm from "views/admin/Posts/Form";
import Investors from "views/admin/Investors";
import Events from "views/admin/Events";
import EventForm from "views/admin/Events/Form";
import FlashNews from "views/admin/FlashNews";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="relative bg-blue-600 md:pt-16 pb-16 pt-8">
          <div className="px-4 md:px-10 mx-auto w-full">
            <AdminNavbar />
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto bg-gray-200 w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Events} />
            {/* <Route path="/admin/events" exact component={Event} /> */}
            <Route path="/admin/featured-projects" exact component={FeaturedProjects} />
            <Route path="/admin/top-launchpads" exact component={TopLaunchpads} />
            <Route path="/admin/category" exact component={EventType} />
            <Route path="/admin/projects" exact component={Projects} />
            <Route path="/admin/project/form" exact component={ProjectForm} />
            <Route path="/admin/airdrops" exact component={Airdrops} />
            <Route path="/admin/airdrops/form" exact component={AirdropsForm} />
            <Route path="/admin/campaigns" exact component={Campaigns} />
            <Route path="/admin/moonsheet" exact component={MoonSheet} />
            <Route path="/admin/ecosystem" exact component={EcoSystem} />
            <Route path="/admin/posts" exact component={Posts} />
            <Route path="/admin/post/form" exact component={PostForm} />
            <Route path="/admin/investors" exact component={Investors} />
            <Route path="/admin/events" exact component={Events} />
            <Route path="/admin/event/form" exact component={EventForm} />
            <Route path="/admin/flashnews" exact component={FlashNews} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </div>
      </div>
    </>
  );
}
