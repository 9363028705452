import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import axios from "axios";
import { URLs } from "constants/urls";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import TextError from "../../common/TextError";
import LogoDropdown from "components/UI/LogoDropdown";
import "../../assets/styles/scrollbar.css";
import { toast } from "react-toastify";
import { toastNotification } from "common/util_function";



const AirdropForm = ({ formData, config, setFieldValue, formSubmit }) => {
  const [content, setContent] = useState("");
  const [selectedProjectLogo, setSelectedProjectLogo] = useState();
  const { projectList, eventTypes, airdropStatus } = config;
  const [select, setSelected]  = useState('');
  const [optionList,setOptionList] = useState([]);
  const [counter, setCounter] = useState(0);
  const [formValues, setFormValues] = useState([{ mainstep: "" }]);
  const [numberOfSteps, setnumber] = useState(0);
  const [nsteps, setnsteps] = useState();
  const [acateg, setacateg] = useState();
  const [showContent, setShowContent] = useState(false);
  const [showedate, setShowedate] = useState(true);
  const [showecounter, setShowecounter] = useState(false);
  const [formDatas, setFormDatas] = useState(formData);
  const [stepCount, setStepCount] = useState();

  useEffect(() => { 
    if(formData.subStepsDetails)
    {
      
      setFormValues(formData.subStepsDetails);
      //removeFormFields(formData.subStepsDetails?.length-1)
    }
    
    if (formData.project) {
      setSelectedProjectLogo(formData.project);
    }
    if (formData.content) {
      setContent(formData.content)
    }
    if(formData.numberOfSteps)
    {
      setnumber(formData.numberOfSteps);
      setShowContent(true);
    }
    const timer = setTimeout(() => {
            
      if(formData.stepsDetails)
      {
        for(let l=0;l<formData.stepsDetails?.length;l++)
        {          
          for(let g=0;g<formData.stepsDetails[l]?.length;g++)
          {            
            if(formData.stepsDetails[l][g][1]!="" || formData.stepsDetails[l][g][2]!="")
            {
              if(g>0)
              {
                handleAddClick(g);
              }
            
              var elem = document.getElementById('mainstep'+l);
              var elems = document.getElementById('subStepTitle'+l+g);
              var elemss = document.getElementById('substep'+l+g);
              if((typeof elem !== 'undefined' && elem !== null) && (typeof elems !== 'undefined' && elems !== null) && (typeof elems !== 'undefined' && elems !== null)) {              
              //  if((typeof elem !== 'undefined' && elem !== null)) {              
                document.getElementById('subStepTitle'+l+g).value = formData.stepsDetails[l][g][1];
                document.getElementById('substep'+l+g).value = formData.stepsDetails[l][g][2]?.replace(/\\n/g, "\n");
                document.getElementById('mainstep'+l).value = formData.stepsDetails[l][g][0];
              }
              
              // if(l>0 && formData.stepsDetails[l][g][2]=="")
              // {
                
              //   handleRemoveClick(l);
              // }
            }
          }
        }
      }
      
    }, 1500);
    
    return () => { clearTimeout(timer); }

  }, [formData]);

  const setairdropcateg = (event) => {
    const categval = event.target.value;
    setacateg(categval);
    if(categval=="56")
    {
      setShowecounter(true);
      setShowedate(false);
    }
    else
    {
      setShowedate(true);
      setShowecounter(false);
    }
  };

  const setnumberofinput = (event) => {
    const value = event.target.value;
    setnumber(value);
    setShowContent(true);
  };

  const fields_n = [];
  for (let i = 1; i <= numberOfSteps; i++) {
    fields_n.push(i);
  }


  const validationSchema = Yup.object({
     projectId: Yup.number().required("Project Id is mandatory"),
     categoryId: Yup.number().required("Airdrop Type is mandatory"),
     statusId: Yup.string().required("Status is mandatory"),
    //content: Yup.string().required("Step by Step is mandatory"),
  });

  const buildOptions = () => {
    var arr = [];
    arr.push(<option value="">-Select Steps-</option>)
    for (let i = 1; i <= 20; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }

    return arr; 
}

const numsteps = buildOptions();


const handleChange = (i, e) => {
  let newFormValues = [...formValues];
  newFormValues[i][e.target.name] = e.target.value;
  setFormValues(newFormValues);
}

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    
    let mainStepArr = [];
    for(let s=0;s<formValues.length;s++)
    {
      
      let stepArr = [];
      for(let k=0;k<formDatas.stepsData.length;k++)
      {
        let sstepTitle = document.getElementById('subStepTitle'+s+k).value;
        let substep = document.getElementById('substep'+s+k).value;
        stepArr.push(sstepTitle, substep);
      }
      mainStepArr.push(document.getElementById('mainstep'+s).value,stepArr);
    }
    await formSubmit({ ...fields}, JSON.stringify(formValues), JSON.stringify(mainStepArr), numberOfSteps);
  }


  // form dropdown
  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name.trim()}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

    // form dropdown
    const getDropdownOptionsStatus = (list, defaultName) => {
      const defaultOption = <option key="default" value="Select">{defaultName}</option>
      const options = list.map((item, index) => {
        return (
          <option key={index} value={item.name.trim()}>
            {item.name.trim()}
          </option>
        );
      });
      return [defaultOption, ...options];
    };

  const addFormFields = () => {
    setFormValues([...formValues, {  mainstep: "" }])
 }
 
 const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}

// handle click event of the Remove button
const handleRemoveClick = (index) => {
  const list = [...formDatas.stepsData];
  list.splice(index, 1);
  setFormDatas({ ...formDatas, stepsData: list });
};

// handle click event of the Add button
const handleAddClick = (index) => {
  const currentRow = formDatas.stepsData[index];
    setFormDatas({
      ...formDatas,
      stepsData: [
        ...formDatas.stepsData,
        {
          subStepTitle: "",
          subStep: ""
        },
      ],
    });
};

// const handleInputChange = (e, index) => {
    
//   const { name, value } = e.target;
//   const list = [...formDatas.stepsData];
//   list[index][name] = value;
//   setFieldValue("stepsData", list);
//   // console.log("pppp",list)
//   setFormDatas({ ...formDatas, stepsData: list });
// };


  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      
      {({ values, setFieldValue, setFieldTouched, isSubmitting, errors, resetForm }) => (
        <Form noValidate>
          <div className="px-8 py-4">
            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Project<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full pr-4">
                  <LogoDropdown
                    list={projectList}
                    selectedProjectItem={selectedProjectLogo}
                    onLogo={(item) => {
                      setSelectedProjectLogo(item);
                      setFieldValue('projectId', item.id)
                    }}
                    name="projectId"
                    value={values.projectId}
                  />
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="projectId" />
                  </div>
                </div>
              </div>

              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Airdrop Category<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full">
                <Field
                    as="select"
                    name="categoryId"
                    required
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                    value={values.categoryId} 
                    // onChange={setairdropcateg}
                    >
                    {getDropdownOptions(eventTypes, "--Select Category--")}
                  </Field>
                
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="categoryId" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between px-4 py-2">
            <div className="w-1/2">
                <label className="block text-xs mb-2 uppercase font-bold">
                  Status<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full pr-4">
                <Field as="select" name="statusId" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300">
                  {getDropdownOptionsStatus(airdropStatus, "--Select Status--")}
                </Field>
            <ErrorMessage name="statusId" component="div" className="text-red-500 text-sm" />
                  {/* <div className="text-red-500 text-sm">
                    <ErrorMessage name="airdropcateg" />
                  </div> */}
                </div>
              </div>
              {showedate && (
              <div className="w-1/2" id="enddatedisp">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  <i className="text-gray-500 fa fa-calendar text-lg leading-lg mr-3" />
                  END Date
                </label>
                <div className="w-full">
                  <DatePicker
                    name="endDate"
                    selected={values.endDate}
                    autoComplete="off"
                    isClearable
                    timeInputLabel="To Time:"
                    dateFormat="MM/dd/yyyy h:mm"
                    showTimeInput
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                    onChange={(date) => setFieldValue("endDate", date)} />
                  <ErrorMessage name="endDate" component={TextError} className="text-red-500 text-sm" />
                </div>
              </div>
              )}
              {showecounter && (
              <div className="w-1/2" id="timercount">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  <i className="text-gray-500 fa fa-calendar text-lg leading-lg mr-3" />
                  Countdown
                </label>
                <div className="w-full">
                <Field type="text" name="countdowntimer" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Countdown" autoComplete="off" value={values.countdowntimer} />
                </div>
              </div>
              )}
            </div>
              
            <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Rewards</label>
          <div className="w-full pr-4">
            <Field type="text" name="avgCost" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Estimated Rewards / Avg Cost" autoComplete="off" value={values.avgCost} />
            <ErrorMessage name="avgCost" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Video Link</label>
          <div className="w-full">
            <Field type="text" name="videoLink" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Video Link" autoComplete="off" value={values.videoLink} />
            <ErrorMessage name="videoLink" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
      <div className="w-1/2 ">
        <label className="block w-full text-xs mb-2 uppercase font-bold">Number of Steps</label>
        <div className="w-full">
        <Field as="select" id="selSteps" name="numberOfSteps" value={values.numberOfSteps} className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" onChange={setnumberofinput}>
        {numsteps}
      </Field>
      <ErrorMessage name="numberOfSteps" component="div" className="text-red-500 text-sm" />
        </div>
      </div>
      </div>
      
      <div className="flex flex-wrap items-center justify-between px-4 py-2">
      { numberOfSteps ?         
        <>
        
        {formValues.map((element, str) => {
          return(
            showContent && (
          <div className="w-full" style={{borderBottom: '1px solid #aaa'}}>
            <br></br>
            <label className="block text-xs mb-2 uppercase font-bold">Step {str+1} 
            {
              str ? 
                <button type="button" className="ml-4" variant="secondary" onClick={() => removeFormFields(str)}>( Remove Step {str+1} - <i className="fas fa-trash text-gray-800"></i> )</button> 
              : null
            }

            </label>
            <div className="w-full">
            <Field type="text" name={`mainstep`+str} id={`mainstep`+str} className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Add Detail" autoComplete="off" />
            {setStepCount(str)}
            <span className="text-gray-800 text-xl font-bold p-4 mb-4">Sub Step Details</span>
            <div className="w-full p-4 bg-white custom-scrollbar">
            <table>
            <thead>
             
            </thead>
            <tbody>
              {formDatas.stepsData &&
                formDatas.stepsData.map((row, i) => {
                  return (
                    <>
                    <tr key={`${str}-${i}`}>
                      <td style={{width: '140px'}}>Step Sub Title</td>
                      <td>
                        <Field type="text"
                          name={`subStepTitle`+str+i}
                          id={`subStepTitle`+str+i}
                          placeholder="Sub Step Title"
                          style={{width: '100%'}}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      </tr>
                      <tr>
                      <td style={{width: '140px'}}>Step Sub Details</td>
                      <td>
                        {/* <CBEditor name="subStep" initialValue={values.subStep} onEditorChange={(text) => {
                  setContent(text) 
                }} onChange={(e) => handleInputChange(e, i)} /> */}
                      <Field as="textarea"
                          name={`substep`+str+i}
                          id={`substep`+str+i}
                          placeholder="Sub Step Details"
                          style={{width: '100%'}}
                          className="p-2 border-1 max-w-800-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      </tr>
                      <tr style={{borderBottom: '1px solid #aaa'}}>
                      <td>
                        {/* <div className="btn-container"> */}
                          {formDatas.stepsData.length === i + 1 && (
                            <button
                              className="float-right"
                              variant="secondary"
                              onClick={() => handleAddClick(i)}
                            >
                              
                              <i className="fas fa-plus text-gray-800"></i>
                            </button>
                          )}
                          &nbsp;&nbsp;&nbsp;
                          {i !== 0 && (
                            <button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <i className="fas fa-trash text-gray-800"></i>
                            </button>
                          )}
                        {/* </div> */}
                      </td>
                    </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          </div>
            
            </div>
          </div>
          )
          
        )})} 
        <br></br>
        {showContent && (stepCount < (numberOfSteps-1)) && (
          <div className="button-section" id="button_morestep">
              <button className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" type="button" onClick={() => addFormFields()}>Add More Steps</button>
          </div>
        )
        }
        </>
        :'' }
          </div>
          </div>
          <div className="px-4 py-2 border-t-2 flex items-center justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" onClick={resetForm}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
          </div>
        </Form>
      )}
    </Formik >
  );

}
export default AirdropForm;