import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toastNotification } from "common/util_function";
import Overlay from "components/UI/Overlay";
import styles from "./Login.module.css";
import Logo from 'components/UI/Logo';
import * as Yup from 'yup';

import { userService } from '../services/user.service';

const ChangePassword = () => {
  const [form, setForm] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  });
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const onSubmit = async ({ oldPassword, password, confirmPassword }, { setSubmitting }) => {
    const response = await userService.changePassword({ oldPassword, password, confirmPassword });
    if (response.type === "SUCCESS") {
      setForm({
        oldPassword: '',
        password: '',
        confirmPassword: ''
      })
      toastNotification("success", response.message);
      history.push("/");
    } else {
      setSubmitting(false);
      toastNotification("error", response.message);
    }
  }

  return (
    <div className="relative">
      <Overlay />
      <div
        className={`${styles["login-bg-wrapper"]} absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full`}
      ></div>
      <div className={`${styles["login-container"]}`}>
        <div className="flex items-center bg-indigo-700">
          <div
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg
            rounded-lg bg-gray-800 border-0 p-5"
          >
            <Logo className="mb-4" />
            <Formik initialValues={form} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="mb-6">
                    <label className="block text-xs text-white mb-2 uppercase font-bold">Old Password</label>
                    <Field name="oldPassword" type="password" className={`w-full p-2 border-2 outline-none focus:bg-gray-300 ${errors.oldPassword && touched.oldPassword ? "border border-solid border-red-500" : ''}`} />
                    <ErrorMessage name="oldPassword" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="mb-6">
                    <label className="block text-xs text-white mb-2 uppercase font-bold">Password</label>
                    <Field name="password" type="password" className={`w-full p-2 border-2 outline-none focus:bg-gray-300 ${errors.password && touched.password ? "border border-solid border-red-500" : ''}`} />
                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
                  </div>
                  <div className="mb-6">
                    <label className="block text-xs text-white mb-2 uppercase font-bold">Confirm Password</label>
                    <Field name="confirmPassword" type="password" className={`w-full p-2 border-2 outline-none focus:bg-gray-300 ${errors.confirmPassword && touched.confirmPassword ? "border border-solid border-red-500" : ''}`} />
                    <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-sm" />
                  </div>
                  <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 focus:outline-none text-white font-bold py-2 px-4 mb-6 rounded">
                    Submit
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword;