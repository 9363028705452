import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import TextError from "../../common/TextError";
import LogoDropdown from "components/UI/LogoDropdown";
import "./Events.css";
import "../../assets/styles/scrollbar.css";

const EventForm = ({ formData, config, onSubmit }) => {
  const [selectedProjectLogo, setSelectedProjectLogo] = useState();
  const [selectedPlatformLogo, setSelectedPlatformLogo] = useState();
  const { projectList, eventTypes, eventSubCategories, platformList } = config;
  useEffect(() => {
    if (formData.project) {
      setSelectedProjectLogo(formData.project);
    }
    if (formData.platform) {
      setSelectedPlatformLogo(formData.platform);
    }
    return () => { }
  }, [formData])

  const validationSchema = Yup.object({
    projectId: Yup.number().required("Project Id is mandatory"),
    platformId: Yup.number().required("Platform Id is mandatory"),
    categoryId: Yup.string().required("Event Type is mandatory"),
    // tokenPrice: Yup.number().required("Token Price is mandatory"),
  });

  // form dropdown
  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name.trim()}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, setFieldTouched, isSubmitting, errors, resetForm }) => (
        <Form noValidate>
          {console.log("pooooooooo",values)}
          <div className="px-8 py-4">
            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Project<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full pr-4">
                  <LogoDropdown
                    list={projectList}
                    selectedProjectItem={selectedProjectLogo}
                    onLogo={(item) => {
                      setSelectedProjectLogo(item);
                      setFieldValue('projectId', item.id)
                    }}
                    name="projectId"
                    value={values.projectId}
                  />
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="projectId" />
                  </div>
                </div>
              </div>

              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Platform<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full">
                  <LogoDropdown
                    list={platformList}
                    selectedProjectItem={selectedPlatformLogo}
                    onLogo={(item) => {
                      setSelectedPlatformLogo(item);
                      setFieldValue('platformId', item.id)
                    }}
                    name="platformId"
                    value={values.platformId}
                  />
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="platformId" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Event Type<span className="ml-1 text-red-500">*</span>
                </label>
                <div className="w-full pr-4">
                  <Field
                    as="select"
                    name="categoryId"
                    required
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  >
                    {getDropdownOptions(eventTypes, "--Select Category--")}
                  </Field>
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="platformId" />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Event Sub Category
                </label>
                <div className="w-full">
                  <Field
                    as="select"
                    name="subCategoryId"
                    required
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  >
                    {getDropdownOptions(eventSubCategories, "--Select Sub Category--")}
                  </Field>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Token Price
                </label>
                <div className="w-full pr-4">
                  <Field
                    type="text"
                    name="tokenPrice"
                    placeholder="Token Price"
                    required
                    className={`w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300 ${errors.tokenPrice && setFieldTouched.tokenPrice ? "focus:bg-red-500" : ''}`}
                  />
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="tokenPrice" />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Total Raise
                </label>
                <div className="w-full">
                  <Field
                    type="text"
                    name="totalRaise"
                    placeholder="Total Raise"
                    required
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  />
                  <div className="text-red-500 text-sm">
                    <ErrorMessage name="totalRaise" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  More Details Link
                </label>
                <div className="w-full pr-4">
                  <Field
                    type="text"
                    name="moreDetailsLink"
                    placeholder="More Details Link"
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  />
                  <ErrorMessage name="moreDetailsLink" className="text-red-500 text-sm" />
                </div>
              </div>


              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  Releated Event Id
                </label>
                <div className="w-full">
                  <Field
                    type="text"
                    name="relatedEventId"
                    placeholder="Releated Event Id"
                    required
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  <i className="text-gray-500 fa fa-calendar text-lg leading-lg mr-3" />
                  From Date/Time
                </label>
                <div className="w-full pr-4">
                  <DatePicker
                    name="startDate"
                    selected={values.startDate}
                    autoComplete="off"
                    isClearable
                    timeInputLabel="From Time:"
                    dateFormat="MM/dd/yyyy h:mm"
                    showTimeInput
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                    onChange={(date) => setFieldValue("startDate", date)}
                  />
                  <ErrorMessage name="startDate" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="w-1/2">
                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                  <i className="text-gray-500 fa fa-calendar text-lg leading-lg mr-3" />
                  To Date/Time
                </label>
                <div className="w-full">
                  <DatePicker
                    name="endDate"
                    selected={values.endDate}
                    autoComplete="off"
                    isClearable
                    timeInputLabel="To Time:"
                    dateFormat="MM/dd/yyyy h:mm"
                    showTimeInput
                    className="w-full p-3 border-2 cursor-pointer outline-none focus:bg-gray-300"
                    onChange={(date) => setFieldValue("endDate", date)} />
                  <ErrorMessage name="endDate" component={TextError} className="text-red-500 text-sm" />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-2 border-t-2 flex items-center justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" onClick={resetForm}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
          </div>
        </Form>
      )}
    </Formik >
  )
}
export default EventForm;