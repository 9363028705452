import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Activate from "accounts/Activate";
import Login from "accounts/Login";
import ForgotPassword from "accounts/ForgotPassword";
import ResetPassword from "accounts/ResetPassword";
import ChangePassword from "accounts/ChangePassword";
import PrivateRoute from "routers/PrivateRoute";

const User = () => {
  return (
    <Fragment>
      <Switch>
        <PrivateRoute path="/change-password" component={ChangePassword} />
        <Route path="/activate/:id" component={Activate} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/login" component={Login} />
        <Route path="/reset/:id" component={ResetPassword} />
        <Redirect from="*" to="/login" />
      </Switch>
    </Fragment>
  )
};

export default User;