import React, { useCallback, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import { Table, Button } from "react-bootstrap";
import Modal from "../UI/Modal";
import { Field } from 'formik';
import "../../assets/styles/scrollbar.css";
import EventTypesList from "components/EventTypes/List";
import EventTypesForms from "components/EventTypes/Forms";
import { userService } from '../../services/user.service';
import EventTypesFilter from "components/EventTypes/Filter";

const defaultData = {
  name: "",
};

const cliffList = [
  {
    id: "days",
    name: "Days"
  },
  {
    id: "weeks",
    name: "Weeks"
  },
  {
    id: "months",
    name: "Months"
  },
  {
    id: "years",
    name: "Years"
  }
]

const vestingList = [
  {
    id: "months",
    name: "Months"
  },
  {
    id: "years",
    name: "Years"
  }
]

const vestingIntervalList = [
  {
    id: "daily",
    name: "Daily"
  },
  {
    id: "weekly",
    name: "Weekly"
  },
  {
    id: "monthly",
    name: "Monthly"
  },
  {
    id: "quarterly",
    name: "Quarterly"
  },
  {
    id: "yearly",
    name: "Yearly"
  }
]

const AdvancedStep = ({ formFields, values, validateTGE, setFieldValue, config }) => {
  const [ salesRounds, setsalesRoundss ] = useState(config.salesRounds);
  const [showAddButton, setShowAddButton] = useState(true);
  const [formData, setFormData] = useState(formFields);
  const [formDatas, setFormDatas] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [results, setResults] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelectedList, setOptionSelectedList] = useState([]);
  const [isCheckboxDisabled, setIsDisabled] = useState(false);
  const [isCheckboxDisabledcval, setIsDisabledcval] = useState(false);
  const [isCheckboxDisabledvest, setIsDisabledvest] = useState(false);
  const [isCheckboxDisabledvval, setIsDisabledvval] = useState(false);
  const [isCheckboxDisabledvint, setIsDisabledvint] = useState(false);
 

  useEffect(() => {
    list();
    subCategories();
    
    const sum = formData.tableData.reduce((a, b) => a + (b.allocationper ? +b.allocationper : 0), 0);
    setShowAddButton(sum >= 100 ? false : true);
    validateTGE(sum === 100 || sum === 0 ? true : false);
  }, [formData]);

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  const list = async (data) => {
    const config = {
      method: "post",
      url: URLs.eventTypes.list,
      data
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data)
        setIsLoading(false)
      } else {
        setResults([]);
        setIsLoading(false)
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toastNotification("error", error.message);
    }
  }

  const openForm = useCallback((item) => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      categoryTypeId: item.categoryTypeId
    }
    setFormDatas(data);
    setIsOpenForm(true);
  });

  // handle input change
  const handleInput = (e, name) => {
    setFieldValue(name, e.target.value);
    const tempData = { ...formData }
    tempData[name] = e.target.value
    setFormData(tempData)
  }
  const handleInputChange = (e, index) => {
    
    const { name, value } = e.target;
    const list = [...formData.tableData];
    list[index][name] = value;
    if (name === "allocationper") {
      list[index]["tokenFundraised"] = (formData.totalSupply * (value / 100)).toFixed(0);
    }
    if (name === "tokenTGE") {
      if(value=='100')
      {
        setIsDisabled(true); 
        setIsDisabledcval(true);
        setIsDisabledvest(true);
        setIsDisabledvval(true);
        setIsDisabledvint(true);
        
      }
      else
      {
        setIsDisabled(false); 
        setIsDisabledcval(false);
        setIsDisabledvest(false);
        setIsDisabledvval(false);
        setIsDisabledvint(false);
      }
    }
    const sum = list.reduce((a, b) => a + (b.allocationper ? +b.allocationper : 0), 0);
    setFieldValue("tableData", list);
    setFormData({ ...formData, tableData: list });
    setShowAddButton(sum >= 100 ? false : true);
    validateTGE(sum === 100 || sum === 0 ? true : false);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...formData.tableData];
    list.splice(index, 1);
    setFormData({ ...formData, tableData: list });
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    const currentRow = formData.tableData[index];
    if (currentRow.tokenTGE) {
      setFormData({
        ...formData,
        tableData: [
          ...formData.tableData,
          {
            round: "",
            initialPrice: "",
            tokenTGE: "",
            allocationper: "",
            tokenFundraised: "",
            cliffCategory: "",
            cliff: "",
            vestingCategory: "",
            vesting: "",
            vestingInterval: ""
          },
        ],
      });
    }
  };

  const subCategories = () => {
    const url = URLs.category.types;
    axios.get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          setSubCategoryList(results);
          
        } else {
          setSubCategoryList([]);
        }
      })
      .catch((err) => {
        setSubCategoryList([]);
      });
  }

  const getaddedcategory = () => {
    const url = `${URLs.category.list}?typeId=7`;
    axios.get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          setsalesRoundss(results);
                    
        } else {
          
        }
      })
      .catch((err) => {
        
      });
  }

  const onSubmit = async (fields) => {
    const config = {
      method: "post",
      url: URLs.eventTypes.form,
      data: { ...fields, categoryTypeId:"7", userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          getaddedcategory();
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  }

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="p-4">
      <Fragment>
        <div className="flex flex-wrap items-center justify-between px-4 py-2">
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Initial Token Price</label>
            <div className="w-full pr-4">
              <Field type="text" name="tokenPrice" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Token Price" autoComplete="off" />
            </div>
          </div>

          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Initial Market Cap</label>
            <div className="w-full">
              <Field type="text" name="initialMarketCap" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Initial Market Cap" autoComplete="off" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between px-4 py-2 mb-4">
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Initial Token Supply</label>
            <div className="w-full pr-4">
              <Field type="text" name="initialTokenSupply" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Initial Token Supply" autoComplete="off" onChange={(e) => handleInput(e, "initialTokenSupply")} />
            </div>
          </div>
          <div className="w-1/2">
            <label className="block text-xs mb-2 uppercase font-bold">Total Supply</label>
            <div className="w-ful">
              <Field type="text" name="totalSupply" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Total Supply" autoComplete="off" onChange={(e) => handleInput(e, "totalSupply")} />
            </div>
          </div>
        </div>
       
      </Fragment>
      <span className="text-gray-800 text-xl font-bold p-4 mb-4">Sales round details</span>
      <div className="w-full bg-white custom-scrollbar">
        <div className="p-4">
          <table>
            <thead>
              <tr className="text-left">
                <th>Round Category &nbsp;<a className="mr-2" variant="secondary" style={{cursor:'pointer'}} onClick={() => openForm(defaultData)} title="Add New Category">
                        <i className="fas fa-plus"></i>
                        </a></th>
                <th>Token Price</th>
                <th>Round Allocation(%)</th>
                <th>Token Allocation</th>
                <th>TGE(%)</th>
                <th>Cliff</th>
                <th>Vesting</th>
                <th>Vesting Interval</th>
                <th width="115px">Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.tableData &&
                formData.tableData.map((row, i) => {
                  return (
                    <tr key={`${row.roi}-${i}`}>
                      <td>
                        <Field
                          as="select"
                          name="round"
                          value={row.round}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(salesRounds, "--Select Round--")}
                        </Field>
                      </td>
                      <td>
                        <Field type="text"
                          name="initialPrice"
                          placeholder="Initial Price"
                          value={row.initialPrice}
                          onChange={(e) => { handleInputChange(e, i); }}
                          className="p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                      <Field type="text"
                          name="allocationper"
                          placeholder="Percentage"
                          value={row.allocationper}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <Field type="text"
                          name="tokenFundraised"
                          placeholder="Fundraised"
                          // disabled={true}
                          value={row.tokenFundraised}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                      <Field type="text"
                          name="tokenTGE"
                          placeholder="Percentage"
                          value={row.tokenTGE}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <div className="flex items-center justify-betwee">
                          <Field
                            as="select"
                            name="cliffCategory"
                            onChange={(e) => handleInputChange(e, i)}
                            value={row.cliffCategory}
                            className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                            disabled={isCheckboxDisabled}
                          >
                            {getDropdownOptions(cliffList, "--Select Cliff--")}
                          </Field>
                          <Field type="text"
                            name="cliff"
                            placeholder="Cliff Value"
                            value={row.cliff}
                            onChange={(e) => handleInputChange(e, i)}
                            disabled={isCheckboxDisabledcval}
                            className="ml-1 p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-betwee">
                          <Field
                            as="select"
                            name="vestingCategory"
                            onChange={(e) => handleInputChange(e, i)}
                            value={row.vestingCategory}
                            className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                            disabled={isCheckboxDisabledvest}
                          >
                            {getDropdownOptions(vestingList, "--Select Vesting--")}
                          </Field>
                          <Field type="text"
                            name="vesting"
                            placeholder="Vesting Value"
                            value={row.vesting}
                            onChange={(e) => handleInputChange(e, i)}
                            disabled={isCheckboxDisabledvval}
                            className="ml-1 p-2 border-1 max-w-100-px cursor-pointer outline-none focus:bg-gray-300" />
                        </div>
                      </td>
                      <td>
                        <Field
                          as="select"
                          name="vestingInterval"
                          required
                          onChange={(e) => handleInputChange(e, i)}
                          value={row.vestingInterval}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                          disabled={isCheckboxDisabledvint}
                        >
                          {getDropdownOptions(vestingIntervalList, "--Select Interval--")}
                        </Field>
                      </td>
                      <td>
                        <div className="btn-container">
                          {i !== 0 && (
                            <button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <i className="fas fa-trash text-gray-800"></i>
                            </button>
                          )}
                          {formData.tableData.length === i + 1 && formData.totalSupply && showAddButton && (
                            <button
                              className="float-right"
                              variant="secondary"
                              onClick={() => handleAddClick(i)}
                            >
                              <i className="fas fa-plus text-gray-800"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {isOpenForm && (
          <Modal onClose={() => { }} className="cb-large">
            <div className="flex justify-between items-center p-4 bg-blue-600">
              <h6 className="text-white text-xl font-bold uppercase">Add New Event Type</h6>
              <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
            </div>
            <div className="px-8 py-4">
              <EventTypesForms onSubmit={onSubmit} subCategoryList={subCategoryList} formData={formDatas} />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default AdvancedStep;