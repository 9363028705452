import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../../services/user.service';
import { toastNotification } from "common/util_function";
import DForm from "components/Airdrops/Form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { formattedDateTime } from "common/util_function";

const defaultData = {
  id: "",
  projectId: "",
  categoryId: "",
  statusId: "",
  avgCost: "",
  videoLink: "",
  numberofsteps: "",
  endDate: "",
  stepsData: [
    {
      subStepTitle: "",
      subStep: ""
    }
  ],
};

const defaultConfig = {
  projectList: [],
  eventTypes: [],
  airdropStatus: []
}

const EventForm = (props) => {
  const [config, setConfig] = useState(defaultConfig);
  const history = useHistory();
  const formData = { ...defaultData, ...props?.location?.data };

  useEffect(() => {
    const loadData = async () => {
      const projects = await getProjectList();
      const projectList = projects.filter(item => +item.isLaunchpad === 0);
      const eventTypes = await getCategoryList(4);
      const airdropStatus = await getCategoryList(9);
      setConfig({ ...config, projectList, eventTypes, airdropStatus });
    }
    loadData();
    return () => {
      setConfig(defaultConfig)
    }
  }, [])

  const getProjectList = async () => {
    const url = URLs.project.all;
    return await axios.get(url).then((response) => response.data.data);
  };

  const getCategoryList = async (typeId) => {
    const url = `${URLs.category.list}?typeId=${typeId}`;
    return await axios.get(url).then((response) => response.data.data);
  };

  const onSubmit = async (fields,formValues,subStepValue,numberOfSteps) => {
    
    const { stepsData } = fields;
    const userId = userService.userId();
    const formSubmitData = new FormData();
    formSubmitData.append("id", fields.id);
    formSubmitData.append("projectId", fields.projectId);
    formSubmitData.append("categoryId", fields.categoryId);
    formSubmitData.append("statusId", fields.statusId);
    if(fields.endDate=="")
    {
      formSubmitData.append("endDate", "1971-01-01 00:00:00");
    }
    else
    {
      formSubmitData.append("endDate", formattedDateTime(fields.endDate));
    }
    formSubmitData.append("avgCost", fields.avgCost);
    formSubmitData.append("videoLink", fields.videoLink);
    formSubmitData.append("numberOfSteps", numberOfSteps);
    formSubmitData.append("mainStep", formValues);
    formSubmitData.append("stepValues", subStepValue);
    formSubmitData.append("userId", userId);
    const config = {
      method: "post",
      url: URLs.airdrop.form,
      data: formSubmitData,
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          history.push("/admin/airdrops");
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
   
  }

  return (
    <Formik initialValues={formData} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, resetForm }) => (
        <Form noValidate>
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Airdrop Form</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/airdrops"
              >
                Back
              </Link>
            </div>
            <span className="text-xs italic">Please fill all the mandatory(<span className="text-red-500">*</span>) files </span>
          </div>
          <div className="w-full">
            <DForm formSubmit={onSubmit} setFieldValue={setFieldValue} config={config} formData={formData} values={values} />
          </div>
        </div>
      </div>
    </div>
    </Form>
    )}
    </Formik>
  );
}
export default EventForm;