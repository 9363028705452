import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ProjectLogo from "components/UI/ProjectLogo";

const InvestorForm = ({ formSubmit, formData }) => {
  const [file, setFile] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    description: Yup.string().required('Please enter the description')
  });


  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    console.log("Form fields", fields)
    formSubmit(fields, file);
  }

  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, isSubmitting, handleReset }) => (
        <Form noValidate>
          <div className="custom-scrollbar max-h-65vh">
            <Field name="id" type="hidden" value={values.id} />
            <div className="px-4 py-2 flex flex-wrap items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Name<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field type="text" name="name" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Investor Name" autoComplete="off" value={values.name} />
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="flex items-center text-sm mb-2 font-bold">Description<i className="text-red-500 fas fa-star ml-1 text-7px opacity-75"></i></label>
              <div className="w-1/2">
                <Field as="textarea" name="description" className="text-sm w-full p-2 border-2 min-h-100 cursor-pointer outline-none focus:bg-gray-300" placeholder="Description" autoComplete="off" value={values.description} />
                <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-sm mb-2 font-bold">Upload Image</label>
              <div className="w-1/2 flex items-center justify-between">
                {values?.imageURL && <ProjectLogo className="mr-2" logo={{ imageName: values?.imageURL, name: "" }} path="investors" />}
                <Field type="file" name="investorImage" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                  setFile(e.target.files[0]);
                }
                } />
              </div>
            </div>
          </div>
          <div className="px-4 py-2 border-t-2 flex items-center justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" onClick={handleReset}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"> Submit </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default InvestorForm;