import React, { useCallback, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import { Table, Button } from "react-bootstrap";
import Modal from "../UI/Modal";
import { Field, ErrorMessage } from 'formik';
import "../../assets/styles/scrollbar.css";
import EventTypesList from "components/EventTypes/List";
import EventTypesForms from "components/EventTypes/Forms";
import { userService } from '../../services/user.service';
import EventTypesFilter from "components/EventTypes/Filter";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import TextError from "../../common/TextError";

const defaultData = {
  name: "",
};

const monthList = [
  {
    id: "January",
    name: "January"
  },
  {
    id: "February",
    name: "February"
  },
  {
    id: "March",
    name: "March"
  },
  {
    id: "April",
    name: "April"
  },
  {
    id: "May",
    name: "May"
  },
  {
    id: "June",
    name: "June"
  },
  {
    id: "July",
    name: "July"
  },
  {
    id: "August",
    name: "August"
  },
  {
    id: "September",
    name: "September"
  },
  {
    id: "October",
    name: "October"
  },
  {
    id: "November",
    name: "November"
  },
  {
    id: "December",
    name: "December"
  }
]

const FundRaising = ({ formFields, values, setFieldValue, config }) => {

  const { investors } = config;
  const [ fundingRounds, setFundingRounds ] = useState(config.fundingRounds);
  const [showAddButton, setShowAddButton] = useState(true);
  const [formData, setFormData] = useState(formFields);
  const [formDatas, setFormDatas] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [results, setResults] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelectedList, setOptionSelectedList] = useState([]);
  const [selInvestors, setInvestors] = useState(formFields.investors);
  const [selOtherInvestors, setOtherInvestors] = useState(formFields.othinvestors);
  
  const multiStyle = {
    chips: { background: "gray-300" },
    searchBox: {
      border: "none",
      borderRadius: "0px",
      padding: "0.5rem 1rem"
    },
  };

  useEffect(() => {
    subCategories();
  }, [formData]);

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  const openForm = useCallback((item) => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      categoryTypeId: item.categoryTypeId
    }
    setFormDatas(data);
    setIsOpenForm(true);
  });

  // handle input change
  const handleInput = (e, name) => {
    setFieldValue(name, e.target.value);
    const tempData = { ...formData }
    tempData[name] = e.target.value
    setFormData(tempData)
  }

  const subCategories = () => {
    const url = URLs.category.types;
    axios.get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          setSubCategoryList(results);
          
        } else {
          setSubCategoryList([]);
        }
      })
      .catch((err) => {
        setSubCategoryList([]);
      });
  }

  const getCategory = () => {
    const url = `${URLs.category.list}?typeId=7`;
    axios.get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;         
          setFundingRounds(results);
        } 
        else 
        {
          setFundingRounds([]);
        }
      })
      .catch((err) => {
        setFundingRounds([]);
      });
  }

  const onSubmit = async (fields) => {
    const config = {
      method: "post",
      url: URLs.eventTypes.form,
      data: { ...fields, categoryTypeId:"7", userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          getCategory();
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  }

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...formData.fundingData];
    list.splice(index, 1);
    setFormData({ ...formData, fundingData: list });
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    const currentRow = formData.fundingData[index];
    setFormData({
        ...formData,
        fundingData: [
          ...formData.fundingData,
          {
            fundRound: "",
            totalRaised: "",
            fundMonth: "",
            fundYear: "",
            leadInvestors: "",
            otherInvestors: "",
            announcement: ""
          },
        ],
      });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData.fundingData];
    list[index][name] = value;
    setFieldValue("fundingData", list);
    setFormData({ ...formData, fundingData: list });
    
  };

 

  return (
    <div className="p-4">
      <Fragment>
      <span className="text-gray-800 text-xl font-bold p-4 mb-4">Fund Raising Details</span>
        <div className="w-full bg-white custom-scrollbar">
        <div className="p-4">
        <table>
            <thead>
              <tr className="text-left">
                <th>Funding Round <a className="mr-2" variant="secondary" style={{cursor:'pointer'}} onClick={() => openForm(defaultData)} title="Add New Category">
            <i className="fas fa-plus"></i></a></th>
                <th>Total Raised</th>
                <th>Fundraising Month</th>
                <th>Fundraising Year</th>
                <th>Lead Investors</th>
                <th>Other Investors</th>
                <th>Announcement</th>
                <th width="115px">Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.fundingData &&
                formData.fundingData.map((row, i) => {
                  return (
                    <tr key={`${row.roi}-${i}`}>
                      <td>
                      <Field
                          as="select"
                          name="fundRound"
                          value={row.fundRound}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(fundingRounds, "--Select Round--")}
                        </Field>
                      </td>
                      <td>
                        <Field type="text"
                          name="totalRaised"
                          placeholder="Total Raised"
                          value={row.totalRaised}
                          onChange={(e) => { handleInputChange(e, i); }}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                      <Field
                          as="select"
                          name="fundMonth"
                          value={row.fundMonth}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(monthList, "--Select Month--")}
                        </Field>
                      </td>
                      <td>
                      <Field type="text"
                          name="fundYear"
                          placeholder="YYYY"
                          value={row.fundYear}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                      <Field
                          as="select"
                          name="leadInvestors"
                          value={row.leadInvestors}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(investors, "--Select Lead Investors--")}
                        </Field>
                        {/* <Multiselect
                          options={investors}
                          displayValue="name"
                          showCheckbox={true}
                          placeholder="Lead Investors"
                          name="investors"
                          closeOnSelect={false}
                          style={multiStyle}
                          selectedValues={selInvestors}
                          hidePlaceholder={true}
                          onSelect={(selectedList) => {
                            setInvestors(selectedList);
                            const ids = selectedList.map(item => +item.id).join(",");
                            setFieldValue("investors", ids);
                          }}
                          onRemove={(selectedList) => {
                            setInvestors(selectedList);
                            const ids = selectedList.map(item => item.id).join(",");
                            setFieldValue("investors", ids);
                          }}
                          
                        /> */}
                      </td>
                      <td>
                      <Field
                          as="select"
                          name="otherInvestors"
                          value={row.otherInvestors}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 cursor-pointer outline-none focus:bg-gray-300"
                        >
                          {getDropdownOptions(investors, "--Select Investors--")}
                        </Field>
                        {/* <Multiselect
                        options={investors}
                        displayValue="name"
                        showCheckbox={true}
                        placeholder="Other Investors"
                        name="othinvestors"
                        closeOnSelect={false}
                        style={multiStyle}
                        selectedValues={selOtherInvestors}
                        hidePlaceholder={true}
                        onChange={(e) => handleInputChange(e, i)}
                        onSelect={(selectedList) => {
                          setOtherInvestors(selectedList);
                          const ids = selectedList.map(item => +item.id).join(",");
                          setFieldValue("othinvestors", ids);
                        }}
                        onRemove={(selectedList) => {
                          setOtherInvestors(selectedList);
                          const ids = selectedList.map(item => item.id).join(",");
                          setFieldValue("othinvestors", ids);
                        }}
                        
                        /> */}
                      </td>
                      <td>
                      <Field type="text"
                          name="announcement"
                          placeholder="Announcement Link"
                          value={row.announcement}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <div className="btn-container">
                          {i !== 0 && (
                            <button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <i className="fas fa-trash text-gray-800"></i>
                            </button>
                          )}
                          {formData.fundingData.length === i + 1 && (
                            <button
                              className="float-right"
                              variant="secondary"
                              onClick={() => handleAddClick(i)}
                            >
                              <i className="fas fa-plus text-gray-800"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          </div>

          </div>

        {isOpenForm && (
          <Modal onClose={() => { }} className="cb-large">
            <div className="flex justify-between items-center p-4 bg-blue-600">
              <h6 className="text-white text-xl font-bold uppercase">Add New Event Type</h6>
              <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
            </div>
            <div className="px-8 py-4">
              <EventTypesForms onSubmit={onSubmit} subCategoryList={subCategoryList} formData={formDatas} />
            </div>
          </Modal>
        )} 
        
      </Fragment>
      </div>
  );
}

export default FundRaising;