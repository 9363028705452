import React, { useCallback, useEffect, useState } from "react";
import MoonSheetForm from "../../components/MoonSheet/Form";
import MoonSheetList from "../../components/MoonSheet/List";
import { toastNotification } from "../../common/util_function";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import axios from "axios";

const defaultData = {
  id: 0,
  isLogoVisible: 1,
  projectId: 0,
  tableData: [
    {
      roi: "1",
      initial_price: "",
      initial_market_cap: "",
      project: {
        id: "",
        name: "",
        imageName: "",
      },
    },
  ],
};

const MoonSheet = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    total: 0,
  });
  const [projectList, setProjectList] = useState([]);
  const [formData, setFormData] = useState(defaultData);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProjectList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const getProjectList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setProjectList(response.data.data);
        } else {
          setProjectList([]);
        }
      })
      .catch((err) => {
        setProjectList([]);
      });
  };

  const list = useCallback(async (limit, offset) => {
    const config = {
      method: "post",
      url: URLs.moonsheet.list,
      data: { limit, offset },
    };
    setIsLoading(true)
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
        setIsLoading(false)
      } else {
        setResults([]);
        setIsLoading(false)
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toastNotification("error", error.message);
    }
  }, [paginationConfig]);


  const onSubmitHandler = (moonSheetData) => {
    const config = {
      method: "post",
      url: URLs.moonsheet.form,
      data: { ...moonSheetData, userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          list(paginationConfig.limit, paginationConfig.page - 1);
          setFormData(defaultData);
          toastNotification("success", response.data.message);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };

  const onEditHandler = (item) => {
    setFormData(item);
  };

  const onDeleteHandler = (item) => {
    const url = `${URLs.moonsheet.delete}?id=${item.id}&status=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list(paginationConfig.limit, paginationConfig.page - 1);
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the MoonSheet");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <h6 className="text-gray-800 text-xl font-bold">Moon Sheet</h6>
          </div>
          <MoonSheetForm
            projectList={projectList}
            onAddMoonSheet={onSubmitHandler}
            formData={formData}
          />
          <MoonSheetList
            results={results}
            paginationConfig={paginationConfig}
            onFetchRecords={onFetchRecordsHandler}
            onEdit={onEditHandler}
            onDelete={onDeleteHandler}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default MoonSheet;
