import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import actionTypes from "./redux/actionTypes";
import { Route, Switch, HashRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
import PrivateRoute from "./routers/PrivateRoute";
import Admin from "layouts/Admin.js";
import User from "layouts/User";

toast.configure();

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    var timeZone = localStorage.getItem("timeZone");
    var checkedtimeZone = timeZone !== null ? timeZone : "UTC";
    dispatch(actionTypes.timezoneAction.set_timezone(checkedtimeZone));
    return () => { };
  }, [dispatch]);

  return (
    <Fragment>
      <HashRouter>
        <Switch>
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/" component={User} />
        </Switch>
      </HashRouter>
    </Fragment>
  );
}

export default App;
