import React, { useEffect,useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LogoDropdown from "components/UI/LogoDropdown";

const FlashNewsForm = ({ onSubmit, projectList, formData }) => {
  const [selectedProjectLogo, setSelectedProjectLogo] = useState();

  useEffect(() => {
    if (formData.projectId) {
      const project = projectList.find(item => item.id === formData.projectId);
      setSelectedProjectLogo(project);
    }
    return () => { }
  }, [formData, projectList])

  const validationSchema = Yup.object().shape({
    projectId: Yup.number().required("Project Id is mandatory"),
    description: Yup.string().required('Please enter the description')
  });

  const onFormSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    onSubmit(fields);
  }
  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onFormSubmit} >
      {({ values, isSubmitting, setFieldValue, handleReset }) => (
        <Form noValidate>
          <Field name="id" type="hidden" value={values.id} />
          <div className="flex flex-wrap items-center justify-between px-4 py-2">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
              Project<span className="ml-1 text-red-500">*</span>
            </label>
            <div className="w-1/2">
              <LogoDropdown
                list={projectList}
                selectedProjectItem={selectedProjectLogo}
                onLogo={(item) => {
                  setSelectedProjectLogo(item);
                  setFieldValue('projectId', item.id)
                }}
                name="projectId"
                value={values.projectId}
              />
              <div className="text-red-500 text-sm">
                <ErrorMessage name="projectId" />
              </div>
            </div>
          </div>
          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Description</label>
            <div className="w-1/2">
              <Field as="textarea" name="description" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Description" autoComplete="off" value={values.description} />
              <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
            </div>
          </div>
          <div className="px-4 py-2 flex items-center justify-between">
            <label className="block text-xs mb-2 uppercase font-bold">Read More Link</label>
            <div className="w-1/2">
              <Field type="text" name="moreDetailsLink" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Read More Link" autoComplete="off" value={values.moreDetailsLink} />
              <ErrorMessage name="moreDetailsLink" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="px-4 py-2 flex items-center justify-between">
            <span>&nbsp;</span>
            <div className="w-1/2 flex flex-row justify-between">
              <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded" onClick={() => {
                handleReset();
              }}>Clear</button>

              <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FlashNewsForm;