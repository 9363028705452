import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import MoonSheetPreview from "./Preview";
import Pagination from "../UI/Pagination";
import ConfirmModal from "components/UI/ConfirmModal";

const MoonSheetList = ({ results, paginationConfig, onDelete, onFetchRecords, onEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedMoonSheet, setSelectedMoonSheet] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewMoonSheet, setPreviewMoonSheet] = useState({});

  const onRemoveHandler = (item) => {
    setSelectedMoonSheet(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    onEdit(item);
  };

  const onPreviewHandler = (item) => {
    setPreviewMoonSheet(item);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowModal(true);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setShowModal(false);
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedMoonSheet);
    }
  };

  return (
    <div className="p-4">
      <h2 className="mb-4">Moon Sheet List</h2>
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">ID</th>
            <th>MOONSHEET TITLE</th>
            <th>CREATED BY</th>
            <th>CREATED ON</th>
            <th>STATUS</th>
            <th width="115px">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {results.map((item) => {
            return (
              <tr key={item.id}>
                <td>#MS{item.id}</td>
                <td>
                  <ProjectLogo logo={item.project} />
                </td>
                <td>{item.username}</td>
                <td>
                  <DateFormat date={item.modifiedAt} />
                </td>
                <td>
                  <Status value={item.active} />
                </td>
                <td>
                  <div className="btn-container">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => onRemoveHandler(item)}
                    >
                      <i className="fas fa-trash text-gray-400"></i>
                    </Button>
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => onPreviewHandler(item)}
                    >
                      <i className="fas fa-desktop text-gray-400"></i>
                    </Button>
                    <Button
                      className="float-right"
                      variant="secondary"
                      onClick={() => onEditHandler(item)}
                    >
                      <i className="fas fa-edit text-gray-400"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showModal && (
        <Modal onClose={onClose} className="cb-large">
          <MoonSheetPreview moonSheet={previewMoonSheet} />
        </Modal>
      )}
    </div>
  );
};

export default MoonSheetList;
