
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toastNotification } from "common/util_function";
import Overlay from "components/UI/Overlay";
import styles from "./Login.module.css";
import Logo from 'components/UI/Logo';
import * as Yup from 'yup';

import { userService } from '../services/user.service';

const ForgotPassword = () => {
  const [form, setForm] = useState({
    email: ''
  });

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      const user = userService.readJwtToken(token);
      const role = +user.data.userId.slice(-1);
      toastNotification("success", "Already logged-in CryptoLenz");
      if (role === 1) {
        history.push("/admin/dashboard");
      } else {
        history.push("/");
      }
    }
  }, [history]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
  });

  const onSubmit = async ({ email }, { setSubmitting }) => {
    const response = await userService.forgotPassword(email);
    if (response.type === "SUCCESS") {
      setForm({
        email: ''
      })
      toastNotification("success", response.message);
    } else {
      setSubmitting(false);
      toastNotification("error", response.message);
    }
  }

  return (
    <div className="relative">
      <Overlay />
      <div
        className={`${styles["login-bg-wrapper"]} absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full`}
      ></div>
      <div className={`${styles["login-container"]}`}>
        <div className="flex items-center bg-indigo-700">
          <div
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg
            rounded-lg bg-gray-800 border-0 p-5"
          >
            <Logo className="mb-4" />
            <Formik initialValues={form} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <div className="mb-6">
                    <label className="block text-xs text-white mb-2 uppercase font-bold">Email</label>
                    <Field name="email" type="text" className={`w-full p-2 border-2 outline-none focus:bg-gray-300 ${errors.email && touched.email ? "border border-solid border-red-500" : ''}`} />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>
                  <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 focus:outline-none text-white font-bold py-2 px-4 mb-6 rounded">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>}
                    Submit
                  </button>
                  <div className="flex justify-between">
                    <Link to="/login" className="underline text-xs text-white uppercase font-bold">Login</Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;
