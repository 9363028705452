import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import { toastNotification } from "common/util_function";
import EcoSystemForm from "components/EcoSystem/Form";
import EcoSystemList from "components/EcoSystem/List";
import Modal from "components/UI/Modal";

const EcoSystem = () => {
  const [logo, setLogo] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    total: 0,
  });
  const [blockChainList, setBlockChainList] = useState([]);
  const defaultData = {
    id: 0,
    name: "",
    fileName: ""
  }
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);

  useEffect(() => {
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const list = async (limit, offset) => {
    const config = {
      method: "post",
      url: URLs.ecosystem.list,
      data: { limit, offset },
    };

    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setBlockChainList(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
      } else {
        setBlockChainList([]);
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
      setBlockChainList([]);
    }
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onPaginationHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };

  const onSubmit = async (fields, file) => {
    const userId = userService.userId();
    const formSubmitData = new FormData();
    formSubmitData.append("id", fields.id);
    formSubmitData.append("name", fields.name);
    formSubmitData.append("fileName", fields.fileName);
    formSubmitData.append("userId", userId);
    if (!fields.id) {
      formSubmitData.append("active", 1);
    }
    if (file) {
      formSubmitData.append("file", file, file.name)
    }
    const config = {
      method: "post",
      url: URLs.ecosystem.create,
      data: formSubmitData,
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        setTimeout(() => {
          onClose();
        }, 1000);
        refreshPage();
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
    }
  }

  const onEditHandler = (item) => {
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      fileName: item.fileName
    }
    setFormData(data);
    setLogo({ logoID: item.id, logoFile: item.fileName, path: 'blockchain' })
    setIsOpenForm(true)
  };

  const onDeleteHandler = async (item) => {
    const userId = userService.userId();
    const config = {
      method: "post",
      url: URLs.ecosystem.delete,
      data: { id: item.id, userId, active: !(+item.active) },
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        refreshPage();
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">BlockChain List</h6>
              <button type="submit" onClick={() => onEditHandler(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <EcoSystemList
              blockChainList={blockChainList}
              paginationConfig={paginationConfig}
              onFetchRecords={onPaginationHandler}
              onEdit={onEditHandler}
              onDelete={onDeleteHandler}
            />
          </div>
        </div>
      </div>

      {isOpenForm && (
        <Modal onClose={onClose} className="cb-large">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Airdrop Form</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <div className="px-8 py-4">
            <EcoSystemForm formSubmit={onSubmit} formData={formData} logo={logo} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default EcoSystem;