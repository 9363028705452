import React, { useCallback, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import { Table, Button } from "react-bootstrap";
import Modal from "../UI/Modal";
import { Field, ErrorMessage } from 'formik';
import "../../assets/styles/scrollbar.css";
import EventTypesList from "components/EventTypes/List";
import EventTypesForms from "components/EventTypes/Forms";
import { userService } from '../../services/user.service';
import EventTypesFilter from "components/EventTypes/Filter";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import TextError from "../../common/TextError";

const defaultData = {
  name: "",
};


const ProjectTeam = ({ formFields, values, setFieldValue, config }) => {

  const { fundingRounds, investors } = config;
  const [showAddButton, setShowAddButton] = useState(true);
  const [formData, setFormData] = useState(formFields);
  const [formDatas, setFormDatas] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [results, setResults] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionSelectedList, setOptionSelectedList] = useState([]);

  const multiStyle = {
    chips: { background: "gray-300" },
    searchBox: {
      border: "none",
      borderRadius: "0px",
      padding: "0.5rem 1rem"
    },
  };

  useEffect(() => {
    
  }, [formData]);

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...formData.teamsvalue];
    list.splice(index, 1);
    setFormData({ ...formData, teamsvalue: list });
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    const currentRow = formData.teamsvalue[index];
    setFormData({
        ...formData,
        teamsvalue: [
          ...formData.teamsvalue,
          {
            name: "",
            role: "",
            social1: "",
            social2: ""
          },
        ],
      });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formData.teamsvalue];
    list[index][name] = value;
    setFieldValue("teamsvalue", list);
    setFormData({ ...formData, teamsvalue: list });
  };

  return (
    <div className="p-4">
      <Fragment>
      <span className="text-gray-800 text-xl font-bold p-4 mb-4">Team Details</span>
        <div className="w-full p-4 bg-white custom-scrollbar">
        <table>
            <thead>
              <tr className="text-left">
                <th>Name</th>
                <th>Role</th>
                <th>Social Link 1</th>
                <th>Social Link 2</th>
                <th width="115px">Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.teamsvalue &&
                formData.teamsvalue.map((row, i) => {
                  return (
                    <tr key={`${row.roi}-${i}`}>
                      <td>
                      <Field type="text"
                          name="name"
                          placeholder="Name"
                          value={row.name}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <Field type="text"
                          name="role"
                          placeholder="Role"
                          value={row.role}
                          onChange={(e) => { handleInputChange(e, i); }}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300" />
                      </td>
                      <td>
                      <Field type="text"
                          name="social1"
                          placeholder="Social Link 1"
                          value={row.social1}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                      <Field type="text"
                          name="social2"
                          placeholder="Social Link 2"
                          value={row.social2}
                          onChange={(e) => handleInputChange(e, i)}
                          className="p-2 border-1 max-w-500-px cursor-pointer outline-none focus:bg-gray-300"
                        />
                      </td>
                      <td>
                        <div className="btn-container">
                          {i !== 0 && (
                            <button
                              className="mr-2"
                              variant="secondary"
                              onClick={() => handleRemoveClick(i)}
                            >
                              <i className="fas fa-trash text-gray-800"></i>
                            </button>
                          )}
                          {formData.teamsvalue.length === i + 1 && (
                            <button
                              className="float-right"
                              variant="secondary"
                              onClick={() => handleAddClick(i)}
                            >
                              <i className="fas fa-plus text-gray-800"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Fragment>
      
    </div>
  );
}

export default ProjectTeam;