import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { updateTextInputStyle } from "../../common/util_function";

const EventTypesForms = ({ formData: defaultData, subCategoryList, onSubmit }) => {
  const [formData, setFormData] = useState(defaultData);

  const validationSchema = Yup.object({
    name: Yup.string().required("Value is mandatory"),
    // categoryTypeId: Yup.number().required("Event category is mandatory"),
  });

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    const options = list.map((item, index) => {
      
      return (
       item.id=="7"?
      
        <option key={index} value={item.id} selected>
      
          {item.name.trim()}
        </option>
      :<option key={index} value={item.id}>
      
      {item.name.trim()}
    </option>
      );
      
    });
    return [defaultOption, ...options];
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ touched, errors, resetForm }) => (
        <Form noValidate>
          <div className="custom-scrollbar px-8 py-4 max-h-65vh">
            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-xs mb-2 uppercase font-bold">Value</label>
              <div className="w-1/2">
                <Field
                  type="text"
                  name="name"
                  className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  placeholder="Value"
                  required
                />
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-xs mb-2 uppercase font-bold">Category</label>
              <div className="w-1/2">
                <Field
                  as="select"
                  name="categoryTypeIds"
                  className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300"
                  selected
                >
                  {getDropdownOptions(subCategoryList, "- Select -")}
                </Field>
                <ErrorMessage name="categoryTypeId" component="div" className="text-red-500 text-sm" />
              </div>
            </div>
          </div>

          <div className="px-4 py-2 border-t-2 flex items-center justify-between">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded" onClick={resetForm}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"> Submit </button>
          </div>
        </Form>
      )}
    </Formik >
  )
}
export default EventTypesForms;