import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ProjectLogo from "components/UI/ProjectLogo";
import "../../assets/styles/scrollbar.css";
import CBEditor from "components/UI/Editor";

const PostForm = ({ formSubmit, formData, categoryList }) => {
  const [content, setContent] = useState("");
  const [thumbImageFile, setThumbImageFile] = useState("");

  useEffect(() => {
    if (formData.content) {
      setContent(formData.content)
    }
    return () => { }
  }, [formData])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please enter the post title')
  });

  const handleChangeName = (event, setFieldValue) => {
    const tempArray = event.target.value.trim().replace(/[^a-zA-Z0-9,\s]/g, '').replace(/[\s-]+/g, '-').toLowerCase();
    const slugName = tempArray.split('-').slice(0, 4).join('-');
    setFieldValue("slug", slugName)
  }

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus(true);
    await formSubmit({ ...fields, content }, thumbImageFile);
  }

  return (
    <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={onSubmit} >
      {({ errors, values, setFieldValue, setFieldTouched, isSubmitting, resetForm }) => (
        <Form noValidate>
          <div className="px-8 py-4">
            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block text-xs mb-2 uppercase font-bold">Title<span className="ml-1 text-red-500">*</span></label>
                <div className="w-full pr-4">
                  <Field type="text" name="title" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Title" autoComplete="off" onKeyUp={(e) => {
                    handleChangeName(e, setFieldValue);
                  }} />
                  <ErrorMessage name="title" component="div" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="w-1/2">
                <label className="block text-xs mb-2 uppercase font-bold">Slug Name<span className="ml-1 text-red-500">*</span></label>
                <div className="w-full">
                  <Field type="text" name="slug" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Slug Name" autoComplete="off" value={values.slug} />
                  <ErrorMessage name="slug" component="div" className="text-red-500 text-sm" />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between px-4 py-2">
              <div className="w-1/2">
                <label className="block text-xs mb-2 uppercase font-bold">Post Type<span className="ml-1 text-red-500">*</span></label>
                <div className="w-full pr-4">
                  <Field
                    as="select"
                    name="categoryId"
                    className="px-3 py-3 placeholder-gray-400 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-gray-700 bg-white"
                  >
                    <option value="">--Select Post Type--</option>
                    {
                      categoryList.length && categoryList.map(item => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                      })
                    }
                  </Field>
                  <ErrorMessage name="categoryId" component="div" className="text-red-500 text-sm" />
                </div>
              </div>

              <div className="w-1/2">
                <label className="block text-xs mb-2 uppercase font-bold">Tags
                  <span className="text-sm font-light lowercase">
                    (enter tags separated by commas)
                  </span></label>
                <div className="w-full">
                  <Field type="text" name="tags" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Tags" autoComplete="off" />
                </div>
              </div>
            </div>

            <div className="px-4 py-2 flex items-center justify-between">
              <label className="block text-xs mb-2 uppercase font-bold">Thumbnail Image<span className="ml-1 text-red-500">*</span></label>
              <div className="w-1/2 flex items-center justify-between">
                {values?.thumbnailURL && <ProjectLogo className="mr-2" logo={{ imageName: values?.thumbnailURL, name: "" }} path="posts" />}
                <Field type="file" name="thumbnail" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
                  setThumbImageFile(e.target.files[0]);
                }
                } />
                <ErrorMessage name="thumbnail" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2">
              <label className="block w-full text-xs mb-2 uppercase font-bold">Overview</label>
              <div className="w-full">
                <Field as="textarea" name="excerpt" className="min-h-100 p-2 w-full border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Excerpt" autoComplete="off" value={values.excerpt} />
                <ErrorMessage name="excerpt" component="div" className="text-red-500 text-sm" />
              </div>
            </div>

            <div className="px-4 py-2 ">
              <label className="block w-full text-xs mb-2 uppercase font-bold">Post Content<span className="ml-1 text-red-500">*</span></label>
              <div className="w-full  min-h-200">
                <CBEditor name="content" initialValue={values.content} onEditorChange={(text) => {
                  setContent(text)
                }} />
              </div>
            </div>

          </div>
          <div className="px-4 py-2 border-t-2 flex items-center justify-end">
            <button type="reset" className="bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-6 rounded mr-4" onClick={resetForm}>Clear</button>
            <button type="submit" className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>} Submit </button>
          </div>
        </Form>
      )
      }
    </Formik >
  );
}

export default PostForm;