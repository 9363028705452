import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import { toastNotification } from "common/util_function";
import FeaturedList from "components/FeaturedProjects/List";
import FeaturedForm from "components/FeaturedProjects/Form";
import Modal from "components/UI/Modal";

const defaultData = {
  id: "",
  projectId: ""
}

const initialPageConfig = {
  limit: 10,
  page: 1,
  total: 0,
};

const FeaturedProjects = () => {
  const [paginationConfig, setPaginationConfig] = useState(initialPageConfig);
  const [projectList, setProjectList] = useState([]);
  const [results, setResults] = useState([]);
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProjectList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const getProjectList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          const projects = results.filter(item => +item.isLaunchpad === 0);
          setProjectList(projects);
        } else {
          setProjectList([]);
        }
      })
      .catch((err) => {
        setProjectList([]);
      });
  };

  const list = useCallback(async (limit, offset) => {
    const config = {
      method: "post",
      url: URLs.featuredProject.list,
      data: { limit, offset },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        const results = response.data.data;
        setResults(results);
        setIsLoading(false);
        setPaginationConfig({
          ...paginationConfig,
          page: offset === 0 ? 1 : paginationConfig.page,
          total: response.data.message,
        });
      } else {
        setResults([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setResults([]);
    }
  }, [paginationConfig]);

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onFetchRecordsHandler = useCallback((limit, page) => {
    list(limit, limit * (page - 1));
  });

  const onSubmit = async (data) => {
    const config = {
      method: "post",
      url: URLs.featuredProject.form,
      data: { ...data, userId: userService.userId() },
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        refreshPage();
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  }

  const openForm = (item) => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      projectId: item.id ? item.project.id : ""
    }
    setFormData(data);
    setIsOpenForm(true)
  };

  const onDeleteHandler = (item) => {
    const url = `${URLs.featuredProject.delete}?id=${item.id}&status=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list(paginationConfig.limit, paginationConfig.page - 1);
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the project");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Featured Project List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <FeaturedList
              results={results}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      {isOpenForm && (
        <Modal onClose={() => { }} className="cb-medium">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Featured Project</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <FeaturedForm formSubmit={onSubmit} formData={formData} projectList={projectList} />
        </Modal>
      )}
    </div>
  );
}

export default FeaturedProjects;
