import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import ProjectView from "./View";
import Loading from "components/UI/Loading";
import { useHistory } from "react-router";

const ProjectList = ({ paginationConfig, onEdit, onFetchRecords, results, ecosystem, isLoading }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const history = useHistory();

  const onViewHandler = (item) => {
    setSelectedItem(item);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    
    const tempData = { ...item };
    
    
    const blist = tempData.ecosystems.map(item => item.id);
    if (tempData.id) {
      tempData.projectName = tempData.project.name;
      tempData.logoFileName = tempData.project.imageName;
      
      if (tempData.socialLinks) {
        formatSocialLinks(tempData);
      }
    }
    tempData.additionalFileName = item.additionalFileName;
    
    const data = {
      id: tempData.id,
      projectName: tempData.projectName,
      slug: tempData.slug,
      description: tempData.description ? tempData.description : "",
      logoFileName: tempData.logoFileName,
      additionalFileName: tempData.additionalFileName ? tempData.additionalFileName : [],
      isLaunchpad: tempData.isLaunchpad.toString(),
      tokenName: tempData.tokenName ? tempData.tokenName : "",
      initialTokenSupply: tempData.initialTokenSupply ? tempData.initialTokenSupply : "",
      accessTypes: tempData.accessTypes ? tempData.accessTypes : [],
      investors: tempData.investors ? tempData.investors : [],
      othinvestors: tempData.othinvestors ? tempData.othinvestors : [],
      projectCategorites: tempData.projectCategorites ? tempData.projectCategorites : [],
      status: tempData.status,
      totalSupply: tempData.totalSupply ? tempData.totalSupply : "",
      whitePaperLink: tempData.whitePaperLink ? tempData.whitePaperLink : "",
      smartContractAddress: tempData.smartContractAddress ? tempData.smartContractAddress : "",
      tokenPrice: tempData.tokenPrice ? tempData.tokenPrice : "",
      initialMarketCap: tempData.initialMarketCap ? tempData.initialMarketCap : "",
      tableData: tempData.tableData ? tempData.tableData : [],
      teamsvalue: tempData.teamsvalue ? tempData.teamsvalue : [],
      blockChain: tempData.ecosystems ? tempData.ecosystems : [],
      websiteURL: tempData.websiteURL ? tempData.websiteURL : "",
      twitterURL: tempData.twitterURL ? tempData.twitterURL : "",
      telegramCommunityURL: tempData.telegramCommunityURL ? tempData.telegramCommunityURL : "",
      telegramAnnouncementURL: tempData.telegramAnnouncementURL ? tempData.telegramAnnouncementURL : "",
      discordURL: tempData.discordURL ? tempData.discordURL : "",
      tokenomicslink: tempData.tokenomicslink ? tempData.tokenomicslink : "",
      projectdocument: tempData.projectdocument ? tempData.projectdocument : "",
      mediumURL: tempData.mediumURL ? tempData.mediumURL : "",
      githubURL: tempData.githubURL ? tempData.githubURL : "",
      fundingData: tempData.fundingData ? tempData.fundingData : [],
    }
    
    history.push({
      pathname: "/admin/project/form",
      data: data
    })
  };

  const formatSocialLinks = (data) => {
    for (const item of data.socialLinks) {
      const key = Object.keys(item)[0];
      switch (key) {
        case "web":
          data.websiteURL = Object.values(item)[0];
          break;
        case "telegramCommunity":
          data.telegramCommunityURL = Object.values(item)[0];
          break;
        case "telegram":
          data.telegramAnnouncementURL = Object.values(item)[0];
          break;
        case "discord":
          data.discordURL = Object.values(item)[0];
          break;
        case "medium":
          data.mediumURL = Object.values(item)[0];
          break;
        case "github":
          data.githubURL = Object.values(item)[0];
          break;
        case "twitter":
          data.twitterURL = Object.values(item)[0];
          break;
        case "tokenomics":
          data.tokenomicslink = Object.values(item)[0];
          break;
        case "projectdocument":
          data.projectdocument = Object.values(item)[0];
          break;
        case "whitepaper":
          data.whitePaperLink = Object.values(item)[0];
          break;
        default:
          break;
      }
    }
  }

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-large">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Project Details</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <div className="px-8 py-4">
            <ProjectView item={selectedItem} ecosystem={ecosystem} />
          </div>
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="100px">Id</th>
            <th>Project</th>
            <th>Initial Market Cap</th>
            <th>BlockChains</th>
            <th>Access Types</th>
            <th>Launchpad</th>
            <th>Token Name</th>
            <th>Updated On</th>
            <th>Updated By</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={11}><Loading></Loading></td></tr> :
            results.map((item) => {
              return (
                <tr key={item.id}>
                  <td>#P{item.id}</td>
                  <td>
                    <ProjectLogo logo={item.project} />
                  </td>
                  <td>{item.initialMarketCap}</td>
                  <td><div className="flex flex-wrap items-center"> {
                    item.ecosystems.length ? item.ecosystems.map((eItem, index) => {
                      return eItem.imageName ? <ProjectLogo key={index} logo={{ imageName: eItem.imageName }} path="blockchains" /> : eItem.name;
                    }) : "-"
                  }
                  </div>
                  </td>
                  <td>{item.accessTypes.length ? item.accessTypes.map(type => <span key={type.id} className="inline-block px-2 py-1 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">{type.name}</span>) : "-"}</td>
                  <td>{+item.isLaunchpad === 1 ? "Yes" : "No"}</td>
                  <td>{item.tokenName}</td>
                  <td><DateFormat date={Date.parse(item.modifiedAt)} /></td>
                  <td>{item.username}</td>
                  <td><Status value={item.active} /></td>
                  <td>
                    <div className="btn-container">
                      <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={() => onViewHandler(item)}
                      >
                        <i className="fas fa-eye text-gray-400"></i>
                      </Button>
                      <Button
                        className="float-right"
                        variant="secondary"
                        onClick={() => onEditHandler(item)}
                      >
                        <i className="fas fa-edit text-gray-400"></i>
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>

      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default ProjectList;