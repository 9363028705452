import React, { useState } from "react";
import "./ReadMore.css";

const ReadMore = ({ children, length }) => {
  const text = children;
  const stringCropLength = length ? length : 90;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text break-all">
      {isReadMore ? text.slice(0, stringCropLength) : text}
      {
        text.length > stringCropLength ? <span onClick={toggleReadMore} className="read-or-hide">
          <i className={`text-xs lowercase text-blue-900 ml-1 fas fa-${isReadMore ? 'ellipsis-h' : 'reply'}`}></i>

          {/* <span className="text-xs lowercase ml-1">{isReadMore ? "read more" : "read less"}</span> */}
        </span> : ""
      }
    </p>
  );
};

export default ReadMore;