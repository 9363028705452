import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userService } from '../services/user.service';


const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const currentUserValue = userService.userValue;
    if (currentUserValue) {
      const currentUser = userService.readJwtToken(currentUserValue);
      // check if route is restricted by role
      if (currentUser && currentUser.data) {
        // authorised so return component
        return <Component {...props} />
      } else {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/login' }} />
      }
    }
    // if empty user to home page
    return <Redirect to={{ pathname: '/login' }} />
  }} />
)

export default PrivateRoute;