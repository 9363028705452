import React from "react";

const Loading = () => {
  return <div className="flex items-center justify-center space-x-2">
    <div className="fa-3x">
      <i className="fas fa-spinner fa-spin"></i>
    </div>
  </div>
}

export default Loading;