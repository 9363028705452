import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LogoDropdown from "components/UI/LogoDropdown";

const airdropStatus = [{
  id: "Claimbale",
  name: "Claimbale"
}, {
  id: "Confirmed",
  name: "Confirmed"
}, {
  id: "Closed",
  name: "Closed"
}, {
  id: "Ongoing",
  name: "Ongoing"
}, {
  id: "Rumored",
  name: "Rumored"
}, {
  id: "Upcoming",
  name: "Upcoming"
}]

const AirdropFilter = ({ formData, platformList,categoryTypeList, project, onFilter }) => {
  const [projectLogo, setProjectLogo] = useState();

  const defaultData = {
    categoryTypeId: '',
    statusId: ''
  }

  useEffect(() => {
    if (formData.projectId) {
      const project = platformList.find(item => item.id === formData.projectId);
      setProjectLogo(project);
    }
    
    return () => { }
  }, [])

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    const { projectId, categoryTypeId, statusId, fromDate } = fields;
    setSubmitting(true);
    setStatus(true);
    onFilter({ projectId, categoryTypeId, statusId, fromDate })
  }

  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="">{defaultName}</option>
    const options = list.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.name.trim()}
        </option>
      );
    });
    return [defaultOption, ...options];
  };

  return (
    <div className="p-4">
      <div className="my-4 p-4 border-b-2 bg-white rounded">
        <Formik initialValues={formData} onSubmit={onSubmit}>
          {({ values, setFieldValue, setFieldTouched, isSubmitting, handleReset }) => (
            <Form noValidate className="flex flex-wrap items-end justify-between">
              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Project</label>
                <LogoDropdown
                  list={project}
                  selectedProjectItem={projectLogo}
                  onLogo={(item) => {
                    setProjectLogo(item);
                    setFieldValue('projectId', item.id)
                  }}
                  name="projectId"
                  value={values.projectId}
                />
              </div>

              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Category</label>
                <Field
                  as="select"
                  name="categoryTypeId"
                  className="px-3 py-3 placeholder-gray-400 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-gray-700 bg-white"
                >
                  {getDropdownOptions(categoryTypeList, "--Select Category--")}
                </Field>
              </div>

              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Status</label>
                <Field
                  as="select"
                  name="statusId"
                  className="px-3 py-3 placeholder-gray-400 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-gray-700 bg-white"
                >
                  {getDropdownOptions(airdropStatus, "--Select Category--")}
                </Field>
              </div>

              

              {/* <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left"><i className="fa fa-angle-right" aria-hidden="true"></i> End Date </label>
                <DatePicker name="fromDate" autoComplete="off" selected={values.fromDate} onChange={(date) => {
                  setFieldValue("fromDate", date);
                }} className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  isClearable
                  placeholderText="End Date"
                  dateFormat="MM/dd/yyyy" />
              </div> */}

                      

              <div className="flex flex-row mb-2">
                <button type="reset" className="w-full bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-2 rounded mr-2" onClick={() => {
                 
                  setProjectLogo();
                  handleReset();
                }}>Clear</button>
                <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 text-white focus:outline-none font-bold py-2 px-2 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>}
                  Search
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AirdropFilter;