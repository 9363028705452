import React, { useEffect, useState } from "react";
import { Form, InputGroup, Table, Button } from "react-bootstrap";
import LogoDropdown from "../UI/LogoDropdown";
import "./Form.css";

const MoonSheetForm = ({ projectList, onAddMoonSheet, formData }) => {
  const [titleLogo, setTitleLogo] = useState({});
  const [isShow, setIsShow] = useState(1);
  const [inputList, setInputList] = useState([]);

  useEffect(() => {
    if (projectList.length && formData.projectId) {
      const project = projectList.find(item => item.id === formData.projectId)
      setTitleLogo(project);
    }
    setInputList(formData);
  }, [projectList, formData]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList.tableData];
    list[index][name] = value;
    if (index !== 0) {
      list[index]["initial_price"] =
        list[index]["roi"] * list[0]["initial_price"];
      list[index]["initial_market_cap"] = list[index]["roi"] * list[0]["initial_market_cap"];
    }
    setInputList({ ...formData, tableData: list });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList.tableData];
    list.splice(index, 1);
    setInputList({ ...formData, tableData: list });
  };

  // handle click event of the Add button
  const handleAddClick = (index) => {
    const currentRow = inputList.tableData[index];
    console.log(currentRow, index)
    if (currentRow.roi && currentRow.initial_price && currentRow.initial_market_cap) {
      setInputList({
        ...formData,
        tableData: [
          ...inputList.tableData,
          {
            roi: "",
            initial_price: "",
            initial_market_cap: "",
            projectId: "",
          },
        ],
      });
    }
  };

  // handle checkbox change
  const handleCheckboxChange = (e) => {
    const checkboxValue = +e.target.value === 0 ? 1 : 0;
    setIsShow(checkboxValue);
  };

  // handle form submit
  const handleFormSubmit = (e) => {
    const formSubmitData = {
      ...{ tableData: JSON.stringify(inputList.tableData) },
      ...{
        projectId: titleLogo.id,
        isLogoVisible: isShow,
        id: inputList.id,
      },
    };
    onAddMoonSheet(formSubmitData);
    e.preventDefault();
  };

  // handle on logo selected value
  const onLogoHandler = (selectedItem, index) => {
    const list = [...inputList.tableData];
    list[index]["project"] = selectedItem;
    setInputList({ ...formData, tableData: list });
  };

  return (
    <div className="p-4">
      <h3 className="mb-4">Moon Sheet Form</h3>
      <Form onSubmit={handleFormSubmit}>
        <div className="mb-3">
          <LogoDropdown
            list={projectList}
            selectedProjectItem={titleLogo}
            onLogo={(item) => {
              setTitleLogo(item);
            }}
            name="projectId"
            value={titleLogo.id}
          />
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th width="100px">ROI</th>
              <th>PRICE</th>
              <th>MARKET CAP</th>
              <th width="250px">PROJECT</th>
              <th width="115px">Action</th>
            </tr>
          </thead>
          <tbody>
            {inputList.tableData &&
              inputList.tableData.map((row, i) => {
                return (
                  <tr key={`${row.roi}-${i}`}>
                    <td>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="roi"
                          placeholder="Enter ROI"
                          disabled={i === 0 ? "disabled" : ""}
                          value={row.roi}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            name="initial_price"
                            placeholder="Enter Initial Price"
                            value={row.initial_price}
                            disabled={i !== 0 ? "disabled" : ""}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group>
                        <InputGroup className="mb-2">
                          <InputGroup.Prepend>
                            <InputGroup.Text>$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            name="initial_market_cap"
                            placeholder="Enter Market Cap"
                            disabled={i !== 0 ? "disabled" : ""}
                            value={row.initial_market_cap}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </InputGroup>
                      </Form.Group>
                    </td>
                    <td>
                      <LogoDropdown
                        list={projectList}
                        selectedProjectItem={row.project}
                        onLogo={(item) => onLogoHandler(item, i)}
                      />
                    </td>
                    <td>
                      <div className="btn-container">
                        {i !== 0 && (
                          <Button
                            className="mr-2"
                            variant="secondary"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <i className="fas fa-trash text-gray-400"></i>
                          </Button>
                        )}
                        {inputList.tableData.length === i + 1 && (
                          <Button
                            className="float-right"
                            variant="secondary"
                            onClick={() => handleAddClick(i)}
                          >
                            <i className="fas fa-plus text-gray-400"></i>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Is Project Visible"
            checked={isShow ? "checked" : ""}
            value={isShow}
            onChange={handleCheckboxChange}
          />
        </Form.Group>
        <Button
          variant="primary"
          className="bg-blue-900 text-white active:bg-blue-700 mt-3"
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default MoonSheetForm;
