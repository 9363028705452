import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import { toastNotification, formattedDateTime } from "common/util_function";
import Modal from "components/UI/Modal";
import InvestorForm from "components/Investors/Form";
import InvestorList from "components/Investors/List";

const Investors = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    total: 0,
  });
  const [investorList, setInvestorList] = useState([]);
  const defaultData = {
    id: 0,
    name: "",
    description: "",
    imageURL: ""
  }
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const list = async (limit, offset) => {
    const config = {
      method: "post",
      url: URLs.investors.list,
      data: { limit, offset },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setInvestorList(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
        setIsLoading(false);
      } else {
        setInvestorList([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toastNotification("error", error.message);
      setInvestorList([]);
    }
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };

  const onSubmit = async (fields, file) => {
    console.log("fields", fields)
    const userId = userService.userId();
    const formSubmitData = new FormData();
    formSubmitData.append("id", fields.id);
    formSubmitData.append("name", fields.name);
    formSubmitData.append("description", fields.description);
    formSubmitData.append("imageURL", fields.imageURL);
    formSubmitData.append("userId", userId);
    if (file) {
      formSubmitData.append("file", file, file.name)
    }

    const config = {
      method: "post",
      url: URLs.investors.form,
      data: formSubmitData,
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        refreshPage();
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
    }
  }

  const openForm = (item) => {
    console.log("fields items", item)
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      description: item.description,
      imageURL: item.imageURL
    }
    setFormData(data);
    setIsOpenForm(true)
  };

  const onDeleteHandler = (item) => {
    const url = `${URLs.investors.delete}?id=${item.id}&active=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          refreshPage();
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the campaing");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Investors List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <InvestorList
              investorList={investorList}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      {isOpenForm && (
        <Modal onClose={onClose} className="cb-large">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Investor Form</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <div className="px-8 py-4">
            <InvestorForm formSubmit={onSubmit} formData={formData} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Investors;