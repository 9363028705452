import React, { useEffect, useState } from "react";

const Pagination = ({ paginationConfig, onNavigation, className }) => {
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(paginationConfig.page);
  }, [paginationConfig]);

  const pageRef = paginationConfig.page * paginationConfig.limit;
  const total = paginationConfig.total;
  const isNextPageShow = total <= pageRef;

  const onNavigationHandler = (type) => {
    let page;
    if (type === "next") {
      page = ++paginationConfig.page;
    } else {
      page = --paginationConfig.page;
    }
    setPage(page);
    onNavigation(paginationConfig.limit, page);
  };

  return (
    <div
      className={`flex flex-wrap justify-between mt-2 mr-2 items-center align-center ${className}`}
    >
      <p className="text-gray-700 align-middle whitespace-no-wrap font-semibold">
        {`Showing ${(paginationConfig.page - 1) * paginationConfig.limit + 1
          } - ${pageRef < total ? pageRef : total} of ${total}`}
      </p>
      <div>
        <button
          className={`shadow-lg h-10 w-10 rounded-full outline-none focus:outline-none mr-2 ${page === 1 ? "bg-gray-300" : "bg-blue-500 text-gray-300"
            }`}
          type="button"
          disabled={page === 1}
          onClick={() => onNavigationHandler("prev")}
        >
          <i className="fas fa-chevron-left"></i>
        </button>
        <button
          className={`shadow-lg h-10 w-10 rounded-full outline-none focus:outline-none mr-2 ${isNextPageShow ? "bg-gray-300" : "bg-blue-500 text-gray-300"
            }`}
          type="button"
          disabled={isNextPageShow}
          onClick={() => onNavigationHandler("next")}
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
