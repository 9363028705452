import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../../services/user.service';
import { toastNotification } from "common/util_function";
import EForm from "components/Events/Form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { formattedDateTime } from "common/util_function";

const defaultData = {
  id: "",
  projectId: "",
  platformId: "",
  categoryId: "",
  subCategoryId: "",
  tokenPrice: "",
  totalRaise: "",
  moreDetailsLink: "",
  relatedEventId: "",
  startDate: "",
  endDate: "",
};

const defaultConfig = {
  projectList: [],
  platformList: [],
  eventTypes: [],
  eventSubCategories: []
}

const EventForm = (props) => {
  const [config, setConfig] = useState(defaultConfig);
  const history = useHistory();
  const formData = { ...defaultData, ...props?.location?.data };

  useEffect(() => {
    const loadData = async () => {
      const projects = await getProjectList();
      const projectList = projects.filter(item => +item.isLaunchpad === 0);
      const platformList = projects.filter(item => +item.isLaunchpad === 1);
      const eventTypes = await getCategoryList(1);
      const eventSubCategories = await getCategoryList(2);
      setConfig({ ...config, projectList, platformList, eventTypes, eventSubCategories });
    }
    loadData();
    return () => {
      setConfig(defaultConfig)
    }
  }, [])

  const getProjectList = async () => {
    const url = URLs.project.all;
    return await axios.get(url).then((response) => response.data.data);
  };

  const getCategoryList = async (typeId) => {
    const url = `${URLs.category.list}?typeId=${typeId}`;
    return await axios.get(url).then((response) => response.data.data);
  };

  const onSubmit = async (fields) => {
    if (fields.startDate) {
      fields = { ...fields, startDate: formattedDateTime(fields.startDate) };
    }
    if (fields.endDate) {
      fields = { ...fields, endDate: formattedDateTime(fields.endDate) };
    }
    const config = {
      method: "post",
      url: URLs.events.form,
      data: { ...fields, userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          history.push("/admin/events");
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Event Form</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/events"
              >
                Back
              </Link>
            </div>
            <span className="text-xs italic">Please fill all the mandatory(<span className="text-red-500">*</span>) files </span>
          </div>
          <div className="w-full">
            <EForm onSubmit={onSubmit} config={config} formData={formData} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default EventForm;