import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import ConfirmModal from "components/UI/ConfirmModal";

const EcoSystemList = ({ paginationConfig, onDelete, onEdit, onFetchRecords, blockChainList }) => {
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onRemoveHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    onEdit(item);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>BlockChain</th>
            <th>Updated By</th>
            <th>Updated Date</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {blockChainList.map((item) => {
            const project = { imageName: item.imageName, name: item.name };
            return (
              <tr key={item.id}>
                <td>#BC{item.id}</td>
                <td>
                  <div className="flex">
                    {item.imageName ? <ProjectLogo logo={project} path='blockchains' /> : <span>{item.name}</span>}
                  </div>
                </td>
                <td>{item.username}</td>
                <td><DateFormat date={item.modifiedAt} /></td>
                <td><Status value={item.active} /></td>
                <td>
                  <div className="btn-container">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => onRemoveHandler(item)}
                    >
                      <i className="fas fa-trash text-gray-400"></i>
                    </Button>
                    <Button
                      className="float-right"
                      variant="secondary"
                      onClick={() => onEditHandler(item)}
                    >
                      <i className="fas fa-edit text-gray-400"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default EcoSystemList;