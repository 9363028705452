import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LogoDropdown from "components/UI/LogoDropdown";


const EventFilter = ({ formData, platformList, projectList, onFilter }) => {
  const [projectLogo, setProjectLogo] = useState();
  const [platformLogo, setPlatformLogo] = useState();

  useEffect(() => {
    if (formData.projectId) {
      const project = platformList.find(item => item.id === formData.projectId);
      setProjectLogo(project);
    }
    if (formData.platformId) {
      const platform = platformList.find(item => item.id === formData.platformId);
      setPlatformLogo(platform);
    }
    return () => { }
  }, [])

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    const { projectId, platformId, fromDate, toDate, rador } = fields;
    setSubmitting(true);
    setStatus(true);
    console.log("fields", fields)
    onFilter({ projectId, platformId, fromDate, toDate, rador })
  }

  return (
    <div className="p-4">
      <div className="my-4 p-4 border-b-2 bg-white rounded">
        <Formik initialValues={formData} onSubmit={onSubmit}>
          {({ values, setFieldValue, setFieldTouched, isSubmitting, handleReset }) => (
            <Form noValidate className="flex flex-wrap items-end justify-between">
              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Project</label>
                <LogoDropdown
                  list={projectList}
                  selectedProjectItem={projectLogo}
                  onLogo={(item) => {
                    setProjectLogo(item);
                    setFieldValue('projectId', item.id)
                  }}
                  name="projectId"
                  value={values.projectId}
                />
              </div>

              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">Platform</label>
                <LogoDropdown
                  list={platformList}
                  selectedProjectItem={platformLogo}
                  onLogo={(item) => {
                    setPlatformLogo(item);
                    setFieldValue('platformId', item.id)
                  }}
                  name="platformId"
                  value={values.platformId}
                />
              </div>

              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left"><i className="fa fa-angle-right" aria-hidden="true"></i> From Date </label>
                <DatePicker name="fromDate" autoComplete="off" selected={values.fromDate} onChange={(date) => {
                  setFieldValue("fromDate", date);
                }} className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  isClearable
                  placeholderText="From Date"
                  dateFormat="MM/dd/yyyy" />
              </div>

              <div className="mr-2 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left"><i className="fa fa-angle-right" aria-hidden="true"></i> To Date</label>
                <DatePicker name="toDate" autoComplete="off" selected={values.toDate} onChange={(date) => {
                  setFieldValue("toDate", date);
                }} className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  isClearable
                  placeholderText="To Date"
                  dateFormat="MM/dd/yyyy" />
              </div>

              <div className="mr-4 mb-2">
                <label className="block text-xs mb-2 uppercase font-bold text-left">
                  <Field type="checkbox" className="mr-2" name="rador" />
                  On The Radar
                </label>
              </div>

              <div className="flex flex-row mb-2">
                <button type="reset" className="w-full bg-gray-300 text-gray-100 focus:outline-none font-bold py-2 px-2 rounded mr-2" onClick={() => {
                  setPlatformLogo();
                  setProjectLogo();
                  handleReset();
                }}>Clear</button>
                <button type="submit" disabled={isSubmitting} className="w-full bg-blue-800 text-white focus:outline-none font-bold py-2 px-2 rounded">{isSubmitting && <span className="spinner-border spinner-border-sm mr-1">wait..</span>}
                  Search
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventFilter;