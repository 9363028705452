import React from "react";
import ProjectLogo from "components/UI/ProjectLogo";
import { getecoSystemvalues } from "../../common/util_function";

const ProjectView = ({ item, ecosystem }) => {
  console.log(item)
  return (
    <div className="overflow-y-auto max-h-65vh border mx-4">
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Project Name</label>
        <div className="w-1/2">
        {item?.project.name}
          <ProjectLogo className="mr-2" logo={{ imageName: item?.project.imageName, name : "" }} />
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Description</label>
        <div className="w-1/2">
          {item.description}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Launchpad</label>
        <div className="w-1/2">
          {item.isLaunchpad === 1 ? "Yes" : "No"}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Token Name</label>
        <div className="w-1/2">
          {item.tokenName ? item.tokenName : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">BlockChain (EcoSystem)</label>
        <div className="w-1/2">
          {item.blockChain ? getecoSystemvalues(item.blockChain, ecosystem) : '-'}
        </div>
      </div>

      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Telegram Announcement URL</label>
        <div className="w-1/2">
          {item?.socialLinks[4].telegram ? item?.socialLinks[4].telegram : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Telegram Community URL</label>
        <div className="w-1/2">
          {item?.socialLinks[5].telegramCommunity ? item?.socialLinks[5].telegramCommunity : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Twitter URL</label>
        <div className="w-1/2">
          {item?.socialLinks[1].twitter ? item?.socialLinks[1].twitter : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Website URL</label>
        <div className="w-1/2">
          {item?.socialLinks[0].web ? item?.socialLinks[0].web : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Discord URL</label>
        <div className="w-1/2">
          {item?.socialLinks[6].discord ? item?.socialLinks[6].discord : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Github URL</label>
        <div className="w-1/2">
          {item?.socialLinks[2].github ? item?.socialLinks[2].github : '-'}
        </div>
      </div>
      <div className="px-4 py-2 flex items-center justify-between border-b">
        <label className="block text-xs mb-2 uppercase font-bold">Medium URL</label>
        <div className="w-1/2">
          {item?.socialLinks[3].medium ? item?.socialLinks[3].medium : '-'}
        </div>
      </div>
    </div>
  );
}

export default ProjectView;