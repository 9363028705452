import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import DateFormat from "../UI/DateFormat";
import Status from "../UI/Status";
import ProjectLogo from "../UI/ProjectLogo";
import Modal from "../UI/Modal";
import Pagination from "../UI/Pagination";
import ConfirmModal from "components/UI/ConfirmModal";
import Loading from "components/UI/Loading";
import ReadMore from "components/UI/ReadMore";

const InvestorList = ({ paginationConfig, onDelete, onEdit, onFetchRecords, investorList, isLoading }) => {
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onRemoveHandler = (item) => {
    setSelectedItem(item);
    setDeleteStatus(item.active);
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    setShowConfirmModal(true);
  };

  const onEditHandler = (item) => {
    onEdit(item);
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
  };

  const onNavigationHandler = (limit, page) => {
    onFetchRecords(limit, page);
  };

  const confirmHandler = (action) => {
    document.body.classList.remove("overflow-hidden");
    setShowConfirmModal(false);
    if (action) {
      onDelete(selectedItem);
    }
  };

  return (
    <div className="p-4">
      <Pagination
        className="mb-3"
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
      {showConfirmModal && (
        <Modal onClose={onClose} className="cb-small">
          <ConfirmModal
            description={`Are you sure want to ${+deleteStatus ? "de-activate" : "activate"
              }?`}
            onConfirmModal={confirmHandler}
          />
        </Modal>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="50px">Id</th>
            <th>Name</th>
            <th className="w-30">Description</th>
            <th>Image</th>
            <th>Updated By</th>
            <th>Updated Date</th>
            <th>Active</th>
            <th width="115px">Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? <tr key="loading"><td colSpan={8}><Loading></Loading></td></tr> : investorList.map((row) => {
            return (
              <tr key={row.id}>
                <td>#IN{row.id}</td>
                <td>{row.name}</td>
                <td><ReadMore length="50">{row.description}</ReadMore></td>
                <td>{row.imageURL ? <ProjectLogo logo={{ imageName: row.imageURL }} path="investors" /> : "-"}</td>
                <td><DateFormat date={row.modifiedAt} /></td>
                <td>{row.username}</td>
                <td><Status value={row.active} /></td>
                <td>
                  <div className="btn-container">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => onRemoveHandler(row)}
                    >
                      <i className="fas fa-trash text-gray-400"></i>
                    </Button>
                    <Button
                      className="float-right"
                      variant="secondary"
                      onClick={() => onEditHandler(row)}
                    >
                      <i className="fas fa-edit text-gray-400"></i>
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        paginationConfig={paginationConfig}
        onNavigation={onNavigationHandler}
      />
    </div>
  );
};


export default InvestorList;