import React, {Fragment} from "react";

const ConfirmModal = ({description, onConfirmModal}) => {
  return (
    <Fragment>
      <div className="bg-gray-200 text-xl border-b p-4 h-24">
        <p>{description}</p>
      </div>
      <div className="p-4 flex justify-end rounded-b">
        <button
          className="focus:outline-none py-1 px-2 md:py-2 md:px-3 w-24 mr-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          onClick={() => onConfirmModal(true)}
        >
          OK
        </button>
        <button
          className="focus:outline-none py-1 px-2 md:py-2 md:px-3 w-24 bg-red-700 hover:bg-red-600 text-white rounded"
          onClick={() => onConfirmModal(false)}
        >
          Cancel
        </button>
      </div>
    </Fragment>
  );
};

export default ConfirmModal;
