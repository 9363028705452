// const baseURL = "https://api.cryptobuddy.online";
const baseURL = "https://admin-api.cryptolenz.com";
const imageURL = "https://ds7uldi88krbs.cloudfront.net";
//const baseURL = "http://192.168.1.151/crypto/server";

const apiURL = `${baseURL}/v1/`;


const URLs = {
  //user auth
  register: apiURL + "user/register.php",
  loginURL: apiURL + "user/login.php",
  forgotPasswordURL: apiURL + "user/forgot-password.php",
  resetPasswordURL: apiURL + "user/reset-password.php",
  resendEmailURL: apiURL + "user/resend-activation.php",
  changePasswordURL: apiURL + "user/change-password.php",
  refreshTokenURL: apiURL + "user/refresh-token.php",
  verifyEmailURL: apiURL + "user/verify-email.php",

  //favorites APIs
  addDeleteFavEvent: apiURL + "favorites/addDeleteFavEvent.php",
  getUserFavEvents: apiURL + "favorites/getUserFavEvents.php",

  //posts APIs
  posts: {
    list: apiURL + "posts/list.php",
    form: apiURL + "posts/form.php",
    delete: apiURL + "posts/delete.php",
    upload: apiURL + "posts/upload.php",
  },

  // MoonSheet APIs
  moonsheet: {
    list: apiURL + "moonsheet/list.php",
    form: apiURL + "moonsheet/form.php",
    delete: apiURL + "moonsheet/delete.php"
  },
  // Airdrop APIs
  airdrop: {
    form: `${apiURL}airdrop/form.php`,
    list: `${apiURL}airdrop/list.php`,
    delete: `${apiURL}airdrop/delete.php`
  },

  // Events APIs
  events: {
    form: `${apiURL}events/form.php`,
    list: `${apiURL}events/list.php`,
    delete: `${apiURL}events/delete.php`,
  },

  // Event Types APIs
  eventTypes: {
    form: `${apiURL}event-types/form.php`,
    list: `${apiURL}event-types/list.php`,
    delete: `${apiURL}event-types/delete.php`,
  },

  // Airdrop Vote APIs
  airdropVote: {
    form: `${apiURL}airdrop-vote/form.php`,
    total: `${apiURL}airdrop-vote/total.php`,
    voteCount: `${apiURL}airdrop-vote/vote-count.php`,
    voteValidation: `${apiURL}airdrop-vote/vote-validation.php`
  },

  // Airdrop Favorite APIs
  airdropFavorite: {
    create: `${apiURL}airdrop-favorites/create.php`,
    delete: `${apiURL}airdrop-favorites/delete.php`,
    list: `${apiURL}airdrop-favorites/list.php`
  },

  // EcoSystem Favorite APIs
  ecosystem: {
    create: `${apiURL}ecosystem/create.php`,
    delete: `${apiURL}ecosystem/delete.php`,
    list: `${apiURL}ecosystem/list.php`,
    all: `${apiURL}ecosystem/all.php`
  },

  // Airdrop Status APIs
  airdropStatus: {
    create: `${apiURL}airdrop-status/create.php`,
    list: `${apiURL}airdrop-status/list.php`
  },

  // Flash News APIs
  flashnews: {
    form: `${apiURL}flashnews/form.php`,
    list: `${apiURL}flashnews/list.php`,
    delete: `${apiURL}flashnews/delete.php`,
  },

  // Investors
  investors: {
    form: `${apiURL}investors/form.php`,
    delete: `${apiURL}investors/delete.php`,
    list: `${apiURL}investors/list.php`,
    all: `${apiURL}investors/all.php`
  },

  // Project
  project: {
    form: `${apiURL}project/form.php`,
    delete: `${apiURL}project/delete.php`,
    list: `${apiURL}project/list.php`,
    all: `${apiURL}project/all.php`,
    logoList: `${apiURL}project/logo.php`,
  },

  // Featured Project
  featuredProject: {
    form: `${apiURL}featured-projects/form.php`,
    delete: `${apiURL}featured-projects/delete.php`,
    list: `${apiURL}featured-projects/list.php`,
    platforms: `${apiURL}featured-projects/platforms.php`
  },

  // Category
  category: {
    list: `${apiURL}category/list.php`,
    types: `${apiURL}category/types.php`,
  },

  // campaign APIs
  campaign: {
    enroll: `${apiURL}campaign/enroll.php`,
    form: `${apiURL}campaign/form.php`,
    entryDetails: `${apiURL}campaign/entryDetails.php`,
    details: `${apiURL}campaign/details.php`,
    list: `${apiURL}campaign/list.php`,
    adminList: `${apiURL}campaign/admin-list.php`,
    activeCampaigns: `${apiURL}campaign/getActiveCampaigns.php`,
    delete: `${apiURL}campaign/delete.php`
  },

  // Campaign Referal APIs
  campaignReferal: {
    get: `${apiURL}campaign-referal/get.php`,
    create: `${apiURL}campaign-referal/create.php`,
  },

  // Settings APIs
  settings: {
    create: `${apiURL}settings/create.php`,
    list: `${apiURL}settings/list.php`,
    ipAddress: `${apiURL}ip-address.php`,
  },

  // Search 
  search: {
    create: `${apiURL}search/create.php`,
    trending: `${apiURL}search/trending.php`,
    lookup: `${apiURL}search/search.php`
  },
};

export { baseURL, imageURL, URLs }