import React, { useState } from "react";
import { Field, ErrorMessage } from 'formik';
import ProjectLogo from "components/UI/ProjectLogo";
import AdditionalInfo from "components/UI/AdditionalInfo";
import { Multiselect } from "multiselect-react-dropdown";
import "../../assets/styles/scrollbar.css";

const projectStatus = [{
  id: 1,
  name: "Inception"
}, {
  id: 2,
  name: "Seed/Private"
}, {
  id: 3,
  name: "Public Sale"
}, {
  id: 4,
  name: "Listed"
}]

const BasicStep = ({ formFields, values, setFieldValue, file, addfile, config }) => {
  const { ecosystems, accessTypes, investors, projectCategorites } = config;
  const [blockChains, setBlockChains] = useState(formFields.blockChain);
  const [selInvestors, setInvestors] = useState(formFields.investors);

  const multiStyle = {
    chips: { background: "gray-300" },
    searchBox: {
      border: "none",
      borderRadius: "0px",
      padding: "0.5rem 1rem"
    },
  };

  const handleChangeName = (event) => {
    const tempArray = event.target.value.trim().replace(/[^a-zA-Z0-9,\s]/g, '').replace(/[\s-]+/g, '-').toLowerCase();
    const slugName = tempArray.split('-').slice(0, 4).join('-');
    setFieldValue("slug", slugName)
  }

  // form dropdown
  const getDropdownOptions = (list, defaultName) => {
    const defaultOption = <option key="default" value="Select">{defaultName}</option>
    let options = [];
    if (list?.length) {
      options = list.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        );
      });
    }
    return [defaultOption, ...options];
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Name</label>
          <div className="w-full pr-4">
            <Field type="text" name="projectName" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Project Name" autoComplete="off" onKeyUp={(e) => {
              handleChangeName(e);
            }} />
            <ErrorMessage name="projectName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Slug Name</label>
          <div className="w-full">
            <Field type="text" name="slug" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Slug Name" autoComplete="off" value={values.slug} />
            <ErrorMessage name="slug" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Description</label>
          <div className="w-full pr-4">
          {/* <div style={{'white-space':'pre-line'}}>{values.description}</div> */}
            <Field as="textarea" name="description" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Project Description" autoComplete="off" value={values.description.replace(/\\n/g, "\n")} />
            <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Status</label>
          <div className="w-full">
            <Field as="select" name="status" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300">
              {getDropdownOptions(projectStatus, "--Select Status--")}
            </Field>
            <ErrorMessage name="tokenName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      {/* <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-full">
          <label className="block text-xs mb-2 uppercase font-bold">Project Description</label>
          <Field as="textarea" name="description" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Project Description" autoComplete="off" value={values.description} />
          <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
        </div>
      </div> */}

      {/* <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Status</label>
          <div className="w-full pr-4">
            <Field type="text" name="tokenName" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Token Name" autoComplete="off" value={values.tokenName} />
            <ErrorMessage name="tokenName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Tags
            <span className="text-sm font-light lowercase">
              (enter tags separated by commas)
            </span></label>
          <div className="w-full">
            <Field type="text" name="tags" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Tags" autoComplete="off" />
          </div>
        </div>
      </div> */}

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Token Name</label>
          <div className="w-full pr-4">
            <Field type="text" name="tokenName" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Token Name" autoComplete="off" value={values.tokenName} />
            <ErrorMessage name="tokenName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Category</label>
          <div className="w-full">
          <div className="max-h-100-px border-2 cursor-pointer outline-none bg-white">
          <Multiselect
                options={projectCategorites}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select Categories"
                name="projectCategorites"
                closeOnSelect={false}
                style={multiStyle}
                selectedValues={values.projectCategorites}
                hidePlaceholder={true}
                onSelect={(items) => {
                  setFieldValue("projectCategorites", items);
                }}
                onRemove={(items) => {
                  setFieldValue("projectCategorites", items);
                }}
              
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Access Types</label>
          <div className="w-full pr-4">
            <div className="max-h-100-px border-2 cursor-pointer outline-none bg-white">
            <Multiselect
                options={accessTypes}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select Access Types"
                name="accessTypes"
                closeOnSelect={false}
                style={multiStyle}
                selectedValues={values.accessTypes}
                hidePlaceholder={true}
                onSelect={(items) => {
                  setFieldValue("accessTypes", items);
                }}
                onRemove={(items) => {
                  setFieldValue("accessTypes", items);
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">BlockChain</label>
          <div className="w-full">
            <div className="max-h-100-px border-2 cursor-pointer outline-none bg-white">
              <Multiselect
                options={ecosystems}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select Option"
                name="blockChain"
                closeOnSelect={false}
                style={multiStyle}
                selectedValues={values.blockChain}
                hidePlaceholder={true}
                onSelect={(items) => {
                  setFieldValue("blockChain", items);
                }}
                onRemove={(items) => {
                  setFieldValue("blockChain", items);
                }}
              />
              <ErrorMessage name="blockChain" component="div" className="text-red-500 text-sm" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Upload Image</label>
          <div className="w-full flex flex-wrap items-center">
            <Field type="file" name="fileName" className="p-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" onChange={(e) => {
              file(e.target.files[0]);
            }
            } />
            {values?.logoFileName && <div className="-m-8 flex flex-wrap items-center justify-center"><ProjectLogo className="mr-2" logo={{ imageName: values?.logoFileName, name: "" }} height="50" width="50" /></div>}
            <ErrorMessage name="fileName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
        {/* {<div className="border-2 -m-8 p-8">Preview</div>} */}
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Is Launchpad</label>
          <div role="group" aria-labelledby="my-radio-group" className="w-full flex items-center">
            <label className="cursor-pointer mr-4 inline-flex items-center">
              <Field type="radio" name="isLaunchpad" value="1" className="form-radio h-5 w-5 text-gray-600" />
              <span className="ml-2 text-gray-700">Yes</span>
            </label>
            <label className="cursor-pointer mr-4 inline-flex items-center">
              <Field type="radio" name="isLaunchpad" value="0" className="form-radio h-5 w-5 text-gray-600" />
              <span className="ml-2 text-gray-700">No</span>
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Smart Contract Address</label>
          <div className="w-full pr-4">
            <Field type="text" name="smartContractAddress" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Smart Contract Address" autoComplete="off"  value={values.smartContractAddress} />
            <ErrorMessage name="smartContractAddress" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">White Paper Link</label>
          <div className="w-full">
            <Field type="text" name="whitePaperLink" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="White Paper Link" autoComplete="off" value={values.whitePaperLink} />
            <ErrorMessage name="whitePaperLink" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
       
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Website URL</label>
          <div className="w-full pr-4">
            <Field type="text" name="websiteURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Website URL" autoComplete="off" value={values.websiteURL} />
            <ErrorMessage name="websiteURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Project Document</label>
          <div className="w-full">
            <Field type="text" name="projectdocument" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Project Document" autoComplete="off" value={values.projectdocument} />
            <ErrorMessage name="projectdocument" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Twitter URL</label>
          <div className="w-full pr-4">
            <Field type="text" name="twitterURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Twitter URL" autoComplete="off" value={values.twitterURL} />
            <ErrorMessage name="twitterURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Telegram Community URL</label>
          <div className="w-full">
            <Field type="text" name="telegramCommunityURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Telegram Community URL" autoComplete="off" value={values.telegramCommunityURL} />
            <ErrorMessage name="telegramCommunityURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Telegram Announcement URL</label>
          <div className="w-full pr-4">
            <Field type="text" name="telegramAnnouncementURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Telegram Announcement URL" autoComplete="off" value={values.telegramAnnouncementURL} />
            <ErrorMessage name="telegramAnnouncementURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Discord URL</label>
          <div className="w-full">
            <Field type="text" name="discordURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Discord URL" autoComplete="off" value={values.discordURL} />
            <ErrorMessage name="discordURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Medium URL</label>
          <div className="w-full pr-4">
            <Field type="text" name="mediumURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Medium URL" autoComplete="off" value={values.mediumURL} />
            <ErrorMessage name="mediumURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>

        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Github URL</label>
          <div className="w-full">
            <Field type="text" name="githubURL" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Github URL" autoComplete="off" value={values.githubURL} />
            <ErrorMessage name="githubURL" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between px-4 py-2">
      <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Tokenomics URL</label>
          <div className="w-full pr-4">
            <Field type="text" name="tokenomicslink" className="w-full p-2 border-2 cursor-pointer outline-none focus:bg-gray-300" placeholder="Tokenomics URL" autoComplete="off" value={values.tokenomicslink} />
            <ErrorMessage name="tokenomicslink" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
        <div className="w-1/2">
          <label className="block text-xs mb-2 uppercase font-bold">Additional Information</label>
          <div className="w-full flex flex-wrap items-center">
            <Field type="file" name="addfileName" className="p-2 cursor-pointer outline-none focus:bg-gray-300" autoComplete="off" multiple onChange={(e) => {
              addfile(e.target.files);
            }
            } />
            {values?.additionalFileName && <div className="-m-8 flex flex-wrap items-center justify-center"><AdditionalInfo className="mr-2" addlogo={{ imageName: values?.additionalFileName, name: "" }} height="50" width="50" /></div>}
            <ErrorMessage name="addfileName" component="div" className="text-red-500 text-sm" />
          </div>
        </div>
        </div>

    </div>
  );
}

export default BasicStep;