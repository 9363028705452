import React from "react";
import ReactDOM from "react-dom";
import Card from "./Card";
import "./Modal.css";

const WrapperModal = (props) => {
  const onClose = () => {
    props.onClose();
  };
  return (
    <React.Fragment>
      <div className="backdrop" onClick={onClose}></div>
      <div className={`cb-modal ${props.className}`}>
        <Card className="overflow-hidden">
          <div className="cb-modal-body">{props.children}</div>
        </Card>
      </div>
    </React.Fragment>
  );
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <WrapperModal className={props.className} onClose={props.onClose}>
          {props.children}
        </WrapperModal>,
        document.getElementById("cb-modal")
      )}
    </React.Fragment>
  );
};

export default Modal;
