import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import ProjectList from "components/Project/List";
import ProjectFilter from "components/Project/Filter";
import { Link } from "react-router-dom";

const initialPageConfig = {
  limit: 10,
  page: 1,
  total: 0,
};

const Projects = () => {
  const [logoList, setLogoList] = useState([]);
  const [ecosystem, setEcosystem] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState(initialPageConfig);
  const [results, setResults] = useState([]);
  const [filterData, setFilterData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getEcosystem();
    getLogoList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const getLogoList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setLogoList(response.data.data);
        } else {
          setLogoList([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        setLogoList([]);
      });
  };

  const getEcosystem = () => {
    const url = URLs.ecosystem.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setEcosystem(response.data.data);
        } else {
          setEcosystem([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Error in retrieving Logo List");
        setEcosystem([]);
      });
  };

  const list = useCallback(async (limit, offset, data) => {
    const config = {
      method: "post",
      url: URLs.project.list,
      data: { limit, offset, ...data },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data);
        setIsLoading(false);
        setPaginationConfig({
          ...paginationConfig,
          page: offset === 0 ? 1 : paginationConfig.page,
          total: response.data.message,
        });
      } else {
        setResults([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
      setResults([]);
    }
  }, [paginationConfig]);

  const onFetchRecordsHandler = useCallback((limit, page) => {
    list(limit, limit * (page - 1), filterData);
  });

  const openForm = (item) => {
    const tempData = { ...item };
    const blist = tempData.blockChain && tempData.blockChain.split(',');
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    if (tempData.id) {
      tempData.projectName = tempData.project.name;
      tempData.logoFileName = tempData.project.imageName;
      tempData.additionalFileName = tempData.project.addimageName;
      if (tempData.socialLinks) {
        formatSocialLinks(tempData);
      }
    }
    const data = {
      id: tempData.id,
      projectName: tempData.projectName,
      slug: tempData.slug,
      description: tempData.description ? tempData.description : "",
      logoFileName: tempData.logoFileName,
      additionalFileName: tempData.additionalFileName,
      isLaunchpad: tempData.isLaunchpad,
      tokenName: tempData.tokenName ? tempData.tokenName : "",
      blockChain: tempData.blockChain ? ecosystem.filter(tempData => blist.includes(tempData.id)) : [],
      websiteURL: tempData.websiteURL ? tempData.websiteURL : "",
      twitterURL: tempData.twitterURL ? tempData.twitterURL : "",
      telegramCommunityURL: tempData.telegramCommunityURL ? tempData.telegramCommunityURL : "",
      telegramAnnouncementURL: tempData.telegramAnnouncementURL ? tempData.telegramAnnouncementURL : "",
      discordURL: tempData.discordURL ? tempData.discordURL : "",
      mediumURL: tempData.mediumURL ? tempData.mediumURL : "",
      githubURL: tempData.githubURL ? tempData.githubURL : ""
    }
  };

  const formatSocialLinks = (data) => {
    for (const item of data.socialLinks) {
      const key = Object.keys(item)[0];
      switch (key) {
        case "web":
          data.websiteURL = Object.values(item)[0];
          break;
        case "telegramCommunity":
          data.telegramCommunityURL = Object.values(item)[0];
          break;
        case "telegram":
          data.telegramAnnouncementURL = Object.values(item)[0];
          break;
        case "discord":
          data.discordURL = Object.values(item)[0];
          break;
        case "medium":
          data.mediumURL = Object.values(item)[0];
          break;
        case "github":
          data.githubURL = Object.values(item)[0];
          break;
        case "twitter":
          data.twitterURL = Object.values(item)[0];
          break;
        case "tokenomics":
          data.tokenomicslink = Object.values(item)[0];
          break;
        case "projectdocument":
          data.projectdocument = Object.values(item)[0];
          break;
        case "whitepaper":
          data.whitePaperLink = Object.values(item)[0];
          break;
        default:
          break;
      }
    }
  }

  const onFilterHandler = useCallback((data) => {
    setFilterData(data);
    list(paginationConfig.limit, 0, data);
  });

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Project List</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/project/form"
              >
                Add New
              </Link>
            </div>
          </div>
          <div className="w-full">
            <ProjectFilter logoList={logoList} onFilter={onFilterHandler} ecosystem={ecosystem} />

            <ProjectList
              results={results}
              ecosystem={ecosystem}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
