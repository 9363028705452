import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Modal from "components/UI/Modal";
import { URLs } from "constants/urls";
import { toastNotification } from "common/util_function";
import { userService } from '../../services/user.service';
import FlashNewsForm from "components/Flashnews/Form";
import FlashNewsList from "components/Flashnews/List";

const defaultData = {
  projectId: "",
  description: "",
  moreDetailsLink: ""
};

const initialPageConfig = {
  limit: 10,
  page: 1,
  total: 0,
};

const Event = () => {
  const [projectList, setProjectList] = useState([]);
  const [results, setResults] = useState([]);
  const [formData, setFormData] = useState(defaultData);
  const [paginationConfig, setPaginationConfig] = useState(initialPageConfig);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProjectList();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, [paginationConfig.limit, paginationConfig.page])

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const list = useCallback(async (limit, offset, data) => {
    const config = {
      method: "post",
      url: URLs.flashnews.list,
      data: { limit, offset, ...data },
    };
    setIsLoading(true)
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setResults(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
        setIsLoading(false)
      } else {
        setResults([]);
        setIsLoading(false)
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false)
      toastNotification("error", error.message);
    }
  }, [paginationConfig]);

  const getProjectList = () => {
    const url = URLs.project.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          const results = response.data.data;
          setProjectList(results);
        } else {
          setProjectList([]);
        }
      })
      .catch((err) => {
        setProjectList([]);
      });
  };

  const onSubmit = async (fields) => {
    const config = {
      method: "post",
      url: URLs.flashnews.form,
      data: { ...fields, userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          refreshPage();
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          toastNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        toastNotification("error", error);
      });
  }

  const openForm = useCallback((item) => {
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      projectId: item.project?.id,
      description: item.description,
      moreDetailsLink: item.moreDetailsLink
    }
    setFormData(data);
    setIsOpenForm(true);
  });

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };

  const onDeleteHandler = (item) => {
    const config = {
      method: "post",
      url: URLs.flashnews.delete,
      data: { ...item, userId: userService.userId() },
    };

    axios(config)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          list(paginationConfig.limit, paginationConfig.page - 1);
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the event!, Please try again.");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Flash news List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <FlashNewsList
              results={results}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
        {isOpenForm && (
          <Modal onClose={() => { }} className="cb-large">
            <div className="flex justify-between items-center p-4 bg-blue-600">
              <h6 className="text-white text-xl font-bold uppercase">Flash news Form</h6>
              <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
            </div>
            <div className="px-8 py-4">
              <FlashNewsForm onSubmit={onSubmit} projectList={projectList} formData={formData} />
            </div>
          </Modal>
        )}

      </div>
    </div>
  );
}

export default Event;